<template>
  <svg width="40" height="35" viewBox="0 0 40 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 26">
      <rect
        id="Rectangle 22"
        x="0.75"
        y="1.25"
        width="8.5"
        height="32.5"
        rx="2.25"
        stroke="#333333"
        stroke-width="1.5"
      />
      <rect
        id="Rectangle 23"
        x="15.75"
        y="1.25"
        width="8.5"
        height="32.5"
        rx="2.25"
        stroke="#333333"
        stroke-width="1.5"
      />
      <rect
        id="Rectangle 24"
        x="30.75"
        y="1.25"
        width="8.5"
        height="32.5"
        rx="2.25"
        stroke="#333333"
        stroke-width="1.5"
      />
    </g>
  </svg>
</template>
