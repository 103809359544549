<template>
  <div class="relative flex flex-col max-h-screen min-h-[100dvh] bg-brand-tint-lightgreen text-brand-black">
    <!-- Top bar -->
    <a href="/" class="px-4 py-[9px] bg-brand-black flex items-center gap-[6px] text-white text-sm justify-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path
          d="M15.2933 1.2066C15.0663 0.980492 14.7967 0.801613 14.5001 0.6803C14.2036 0.558986 13.8859 0.497642 13.5655 0.499811C13.2451 0.501981 12.9282 0.567621 12.6333 0.69294C12.3384 0.818259 12.0713 1.00077 11.8473 1.22993L2.18067 10.8966L1 15.4999L5.60333 14.3193L15.27 4.6526C15.4992 4.42864 15.6817 4.1615 15.807 3.86659C15.9323 3.57169 15.998 3.25488 16.0001 2.93446C16.0023 2.61405 15.9409 2.29638 15.8196 1.9998C15.6983 1.70323 15.5194 1.43364 15.2933 1.2066V1.2066Z"
          stroke="#FF6C00" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.5707 1.50659L14.9933 4.92926" stroke="#FF6C00" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.1747 2.90259L13.5973 6.32525" stroke="#FF6C00" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2.18085 10.8967L5.60685 14.3161" stroke="#FF6C00" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <span>Ontwerp je eigen pod</span>
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path d="M1 8H16" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9 15L16 8L9 1" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>

    <div
      class="fixed top-9 z-20 w-full px-4 py-2 lg:py-1 bg-gradient-to-b from-brand-black/50 to-transparent flex items-center justify-between lg:justify-start gap-[10px] text-white text-sm">
      <img src="@/assets/images/logo-desktop.svg" alt="OPUS" />
      <div class="flex items-center justify-center w-full gap-2 lg:justify-start">
        <span>Beoordeeld met 9.8</span>
        <span class="text-xl leading-base">&middot;</span>
        <span>Al {{ podCount }} pods geplaatst</span>
      </div>
    </div>

    <ConfigurationContainer :preview="true" />
    <DefaultModal />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import ConfigurationContainer from '@/components/ConfigurationContainer.vue'
import { usePodStore } from '@/stores/pod'
import { useModx } from '@/composables/useModx'
import DefaultModal from '@/components/DefaultModal.vue'
import { useModal } from '@/composables/useModal';

const modx = useModx()

const podStore = usePodStore()

const podCount = ref('200+')

async function getPodCount() {
  try {
    const res: any = await fetch('https://mijn.opuspod.nl/api/cms/pod_count')

    if (!res.ok) {
      return
    }

    const data = await res.json()

    podCount.value = data?.count ?? '200+'

  } catch (e) {
    return;
  }
}

async function previewConfiguration(hash: string | null) {
  if (hash === null) {
    return
  }

  if (!await podStore.previewHash(hash)) {
    useModal(
      'Er is iets mis gegaan',
      '<div class="flex flex-col gap-4"><div><p>Het lijkt erop dat de link onjuist is en hier geen configuratie aan gekoppeld is. Blijft dit mis gaan? Laat het ons weten of stel jouw eigen pod samen in 3D.</p></div><div><a href="/" class="flex justify-center mt-4 button-orange-contained"><span>Ontwerp je eigen pod </span></a></div></div>'
    )
  }
}

async function prefetchAssets() {
  const version = (await modx).version !== '' ? `?v=${(await modx).version}` : ''

  // Add a prefetch link to the dynamic configurator script
  const link = document.createElement('link')
  link.rel = 'prefetch'
  link.href = import.meta.env.VITE_API_URL + '/assets/js/dynamic_configurator.js' + version
  document.head.appendChild(link)
}

onMounted(() => {
  prefetchAssets()
  getPodCount()

  // Check if the u-parameter is present in the URL
  const urlParams = new URLSearchParams(window.location.search)

  // Check for user-hash
  if (urlParams.has('pod')) {
    const hash = urlParams.get('pod')
    previewConfiguration(hash)
  } else {
    // Redirect to the homepage
    window.location.href = '/'
  }
})
</script>
