<template>
    <svg v-if="pod.options.orientation === 'plat_dak'" xmlns="http://www.w3.org/2000/svg" :class="{
        '-scale-x-100': pod.options.storage_mirrored && hasStorage
    }" viewBox="0 0 40 40" fill="none">
        <rect width="30.5" height="31.5" x="4.75" y="3.75" stroke="currentColor" stroke-width="1.5" rx="2.25" />
        <rect v-if="hasStorage" width="9.401" height="22.401" x="7.949" y="8.281" stroke="currentColor"
            stroke-linejoin="round" stroke-width="1.599" rx="1.237" />
    </svg>
    <svg v-else-if="pod.options.podtype === 'beta'" :class="{
        '-scale-x-100': pod.options.orientation === 'rechts_hoog'
    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 38" fill="none">
        <path stroke="#333" stroke-linejoin="round" stroke-width="1.5"
            d="M31.5 34.5V11.36a2 2 0 0 0-1.402-1.908l-26-8.139A2 2 0 0 0 1.5 3.222V34.5a2 2 0 0 0 2 2h26a2 2 0 0 0 2-2Z" />
        <path v-if="hasStorage" stroke="#333" stroke-linejoin="round" stroke-width="1.5"
            d="M29.17 32.612V13.05c0-.572-.189-1.078-.468-1.25l-8.693-2.39c-.71-.029-.869.365-.869 1.249v21.953c0 .723.3 1.31.669 1.31h8.693c.37 0 .669-.587.669-1.31z" />
    </svg>
    <svg v-else :class="{
        '-scale-x-100': pod.options.orientation === 'rechts_hoog'
    }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 38" fill="none">
        <path stroke="#333" stroke-linejoin="round" stroke-width="1.5"
            d="M31.5 34.5V11.36a2 2 0 0 0-1.402-1.908l-26-8.139A2 2 0 0 0 1.5 3.222V34.5a2 2 0 0 0 2 2h26a2 2 0 0 0 2-2Z" />
        <path v-if="hasStorage" stroke="#333" stroke-linejoin="round" stroke-width="1.5"
            d="M13.858 32.35V9.291c0-.675-.189-1.271-.466-1.472L4.749 5c-.706-.034-.864.431-.864 1.472V32.35c0 .853.298 1.543.665 1.543h8.643c.367 0 .665-.69.665-1.543z" />
    </svg>
</template>
<script setup lang="ts">
defineProps<{
    hasStorage: Boolean
}>()

import { usePodStore } from '@/stores/pod'

const pod = usePodStore()
</script>