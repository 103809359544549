export function useCamera(
  position:
    | 'bovenzijde'
    | 'rechterzijde'
    | 'linkerzijde'
    | 'deur_links'
    | 'deur_rechts'
    | 'vooraanzicht'
    | 'onderzijde'
    | 'portaal'
) {
  if (window.pod === undefined || window.pod.engine === undefined) {
    return
  }

  window.pod.panCameraToName(
    window.pod.current_camera, // Get the name of the current camera
    position,
    2,
    true
  )
}
