import '@/assets/css/main.scss'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import * as Sentry from '@sentry/vue'
import { createGtm } from '@gtm-support/vue-gtm'

import App from '@/App.vue'
import router from '@/router'

const app = createApp(App)

if (import.meta.env.VITE_ENV === 'production') {
  Sentry.init({
    app,
    dsn: 'https://36e47276faf3c528173d3284a5737f0b@errors.rds-online.nl/2',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllText: false,
        maskAllInputs: true
      })
    ],
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    allowUrls: ['opuspod.nl'],
    beforeSend(event) {
      if (
        event.exception?.values?.[0]?.stacktrace?.frames?.some((f) =>
          f.filename?.includes(`https://www.googletagmanager.com`)
        )
      ) {
        return null
      }

      return event
    }
  })

  // Add GTM
  app.use(
    createGtm({
      id: 'GTM-5LPR9WG',
      defer: false,
      compatibility: false,
      enabled: true,
      debug: false,
      loadScript: false,
      vueRouter: router,
      trackOnNextTick: false
    })
  )
}

app.use(createPinia())
app.use(router)

app.mount('#app')
