import { useModxStore } from '@/stores/modx'

export async function useModx() {
  const modxStore = useModxStore()

  if (modxStore.version === '') {
    await modxStore.fetchData()
  }

  return modxStore
}
