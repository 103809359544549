<template>
  <div class="grid divide-y divide-brand-tint-lightgreen border-y border-brand-tint-lightgreen">
    <div class="flex items-center justify-between gap-3 py-3">
      <input type="hidden" :value="value" :disabled="disabled" />

      <!-- Label -->
      <label>
        <span @click="toggle()">{{ title }}</span>
        <slot name="tooltip"></slot>
        <br v-if="hasDescription" />
        <span @click="toggle()" v-if="hasDescription" class="text-brand-shade-gray">{{
        description
      }}</span>
      </label>

      <!-- Button -->
      <button @click="toggle()" type="button" role="switch" :disabled="disabled"
        :class="value ? 'bg-brand-tint-darkgreen' : 'bg-brand-tint-orange'"
        class="relative inline-flex py-2 pl-1 transition rounded w-[74px] focus:outline-none shrink-0 disabled:bg-opacity-50 disabled:cursor-not-allowed">
        <span :class="{
        'translate-x-7': value,
        'translate-x-1': !value,
        'bg-opacity-50': disabled
      }" class="w-8 h-8 transition duration-300 ease-in-out bg-white rounded shadow-md" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { watch } from 'vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  description: {
    type: String,
    required: false,
    default: ''
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})

const value = defineModel<boolean>()

// Watch disabled state
watch(
  () => props.disabled,
  (disabled) => {
    if (disabled) value.value = false
  }
)

function toggle() {
  if (props.disabled) return
  value.value = !value.value
}

const hasDescription = props.description.length > 0
</script>
