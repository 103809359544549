<template>
  <aside class="w-full xl:max-w-[500px] lg:max-w-[400px] shrink-0 bg-white flex flex-col grow relative">
    <!-- Steps -->
    <StepNavigation v-if="pod.currentStep < 11" />

    <!-- Options wrapper -->
    <div ref="sideBar" class="relative flex flex-col w-full h-0 overflow-y-auto bg-white grow branded-scroll">
      <StepModel v-if="pod.currentStep === 0" />
      <StepSize v-if="pod.currentStep === 1" />
      <StepOrientation v-if="pod.currentStep === 2" />
      <StepDoors v-if="pod.currentStep === 3" />
      <StepWindows v-if="pod.currentStep === 4" />
      <StepOptions v-if="pod.currentStep === 5" />
      <StepTransport v-if="pod.currentStep === 6" />
      <StepFoundation v-if="pod.currentStep === 7" />
      <StepComments v-if="pod.currentStep === 8" />
      <StepSummary v-if="pod.currentStep === 9" />
      <StepQuote v-if="pod.currentStep === 10" />
      <StepQuoteRequested v-if="pod.currentStep === 11" />
    </div>

    <!-- Next-prev -->
    <div v-if="pod.currentStep < 10"
      class="sticky bottom-0 flex flex-col gap-2 px-4 py-2 bg-white shadow lg:gap-4 lg:p-4">
      <div v-show="pod.price != '0'" class="flex items-center justify-between">
        <span class="font-bold text-brand-secondary">Totaalprijs <span class="font-normal text-brand-shade-gray">incl.
            btw</span></span>
        <span v-text="pod.price" class="text-lg font-bold text-brand-green font-condensed"></span>
      </div>
      <div class="flex w-full gap-4">
        <button v-show="pod.currentStep > 0" @click="pod.prevStep()" class="w-1/3 button-outline-orange shrink-0">
          Vorige
        </button>
        <button @click="pod.nextStep()" v-if="pod.currentStep < 10"
          class="button-orange-contained flex gap-[10px] items-center w-full justify-center">
          <!-- <span v-text="pod.nextStepName()"></span> -->
          <span>Volgende</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path d="M1 8.5H16" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9 15.5L16 8.5L9 1.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>
    </div>
  </aside>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue'

import { usePodStore } from '@/stores/pod'

import StepNavigation from '@/components/StepNavigation.vue'
import StepModel from '@/components/Step/StepModel.vue'
import StepSize from '@/components/Step/StepSize.vue'
import StepOrientation from '@/components/Step/StepOrientation.vue'
import StepDoors from '@/components/Step/StepDoors.vue'
import StepWindows from '@/components/Step/StepWindows.vue'
import StepOptions from '@/components/Step/StepOptions.vue'
import StepTransport from '@/components/Step/StepTransport.vue'
import StepFoundation from '@/components/Step/StepFoundation.vue'
import StepComments from '@/components/Step/StepComments.vue'
import StepSummary from '@/components/Step/StepSummary.vue'
import StepQuote from '@/components/Step/StepQuote.vue'
import StepQuoteRequested from '@/components/Step/StepQuoteRequested.vue'

const pod = usePodStore()

const sideBar = ref<HTMLElement | null>(null)

// When the current step changes, scroll to the top of the sidebar
watch(() => pod.currentStep, () => {
  if (sideBar.value) {
    sideBar.value.scrollTo({ top: 0, behavior: 'instant' })
  }
})

// Watch for errors and scroll to the first element containing an error
watch(() => pod.errors, () => {
  if (sideBar.value) {
    // Wait for the next tick to ensure the error elements are rendered
    setTimeout(() => {
      if (!sideBar.value) return
      const errorElement = sideBar.value.querySelector('.error')
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }
})
</script>
