<template>
  <div>
    <div class="category" :class="{ 'error': pod.errors.includes('transport') }">
      <div class="category-label">
        <span>Geschatte afstand</span>
      </div>
      <div>
        <p>
          Kies de geschatte afstand vanaf de weg tot aan de ideale plek voor de pod. Zo kunnen wij
          een eerste inschatting geven voor het type transport dat nodig is.
        </p>
      </div>

      <div class="py-8 option-group">
        <div class="flex items-center justify-between w-full px-3 py-2 rounded bg-brand-tint-lightgreen">
          <svg width="80" height="40" viewBox="0 0 80 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 23.8002V11.3046C13 10.4302 13.568 9.65716 14.4025 9.39593L28.4025 5.01332C29.6906 4.61011 31 5.5723 31 6.92199V23.8002C31 24.9048 30.1046 25.8002 29 25.8002H15C13.8954 25.8002 13 24.9048 13 23.8002Z"
              stroke="#1F4451" stroke-width="1.5" stroke-linejoin="round" />
            <path
              d="M66.75 35.3335C66.75 35.9965 67.0134 36.6324 67.4822 37.1013C67.9511 37.5701 68.587 37.8335 69.25 37.8335C69.913 37.8335 70.5489 37.5701 71.0178 37.1013C71.4866 36.6324 71.75 35.9965 71.75 35.3335C71.75 34.6705 71.4866 34.0346 71.0178 33.5657C70.5489 33.0969 69.913 32.8335 69.25 32.8335C68.587 32.8335 67.9511 33.0969 67.4822 33.5657C67.0134 34.0346 66.75 34.6705 66.75 35.3335Z"
              stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M67 35L50 35" stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18 35L14 35" stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M44 35H24" stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M44.25 35.3335C44.25 35.9965 44.5134 36.6324 44.9822 37.1013C45.4511 37.5701 46.087 37.8335 46.75 37.8335C47.413 37.8335 48.0489 37.5701 48.5178 37.1013C48.9866 36.6324 49.25 35.9965 49.25 35.3335C49.25 34.6705 48.9866 34.0346 48.5178 33.5657C48.0489 33.0969 47.413 32.8335 46.75 32.8335C46.087 32.8335 45.4511 33.0969 44.9822 33.5657C44.5134 34.0346 44.25 34.6705 44.25 35.3335Z"
              stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M9 35.3335C9 35.9965 9.26339 36.6324 9.73223 37.1013C10.2011 37.5701 10.837 37.8335 11.5 37.8335C12.163 37.8335 12.7989 37.5701 13.2678 37.1013C13.7366 36.6324 14 35.9965 14 35.3335C14 34.6705 13.7366 34.0346 13.2678 33.5657C12.7989 33.0969 12.163 32.8335 11.5 32.8335C10.837 32.8335 10.2011 33.0969 9.73223 33.5657C9.26339 34.0346 9 34.6705 9 35.3335Z"
              stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M18 35.3335C18 35.9965 18.2634 36.6324 18.7322 37.1013C19.2011 37.5701 19.837 37.8335 20.5 37.8335C21.163 37.8335 21.7989 37.5701 22.2678 37.1013C22.7366 36.6324 23 35.9965 23 35.3335C23 34.6705 22.7366 34.0346 22.2678 33.5657C21.7989 33.0969 21.163 32.8335 20.5 32.8335C19.837 32.8335 19.2011 33.0969 18.7322 33.5657C18.2634 34.0346 18 34.6705 18 35.3335Z"
              stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M71.75 35.3335H74.25C74.913 35.3335 75.5489 35.0701 76.0178 34.6013C76.4866 34.1324 76.75 33.4965 76.75 32.8335V27.3852C76.7502 26.8641 76.5877 26.356 76.285 25.9318L71.2483 18.8802C71.0169 18.5563 70.7116 18.2924 70.3576 18.1103C70.0037 17.9283 69.6114 17.8334 69.2133 17.8335H64.25C63.587 17.8335 62.9511 18.0969 62.4822 18.5657C62.0134 19.0346 61.75 19.6705 61.75 20.3335V35.3335"
              stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M77 28L3 28" stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M26 28H3V32.6667C3 33.2855 3.26925 33.879 3.7485 34.3166C4.22776 34.7542 4.87778 35 5.55556 35H8.11111"
              stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M54 28H39V22.5833H42.75V15H49L54 22.5833V28Z" stroke="#386B54" stroke-width="1.5"
              stroke-linecap="round" stroke-linejoin="round" />
            <path d="M75.8941 5.82984L43.2941 14.565L42 9.73535L72.1852 1.64726L75.8941 5.82984Z" stroke="#386B54"
              stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M76 13V6" stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M39 35V28" stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 35V28" stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 32V25" stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div class="flex flex-col items-center justify-center text-brand-green">
            <span class="text-sm">{{ selected }}</span>
            <svg width="48" height="14" viewBox="0 0 48 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.80204 13.0938L2.99538 7.28708C2.95764 7.2494 2.92771 7.20465 2.90728 7.15539C2.88686 7.10613 2.87634 7.05333 2.87634 7C2.87634 6.94667 2.88686 6.89387 2.90728 6.84461C2.92771 6.79535 2.95764 6.7506 2.99538 6.71292L8.80204 0.90625"
                stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M37.9792 0.90625L43.7859 6.71292C43.8236 6.7506 43.8536 6.79535 43.874 6.84461C43.8944 6.89387 43.9049 6.94667 43.9049 7C43.9049 7.05333 43.8944 7.10613 43.874 7.15539C43.8536 7.20465 43.8236 7.2494 43.7859 7.28708L37.9792 13.0938"
                stroke="#386B54" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <line x1="4.75" y1="6.75" x2="43.25" y2="6.75" stroke="#386B54" stroke-width="1.5"
                stroke-linecap="round" />
            </svg>
          </div>
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 36.5V13.3609C5 12.4865 5.56803 11.7135 6.40251 11.4523L32.4025 3.31313C33.6906 2.90991 35 3.8721 35 5.22179V36.5C35 37.6046 34.1046 38.5 33 38.5H7C5.89543 38.5 5 37.6046 5 36.5Z"
              stroke="#1F4451" stroke-width="1.5" stroke-linejoin="round" />
            <rect x="8.75" y="15.25" width="9.5" height="19.5" rx="1.25" stroke="#1F4451" stroke-width="1.5"
              stroke-linejoin="round" />
            <rect x="21.75" y="15.25" width="9.5" height="19.5" rx="1.25" stroke="#1F4451" stroke-width="1.5" />
          </svg>
        </div>
      </div>

      <div class="py-8 option-group">
        <InputDropdown v-model="pod.options.distance" :options="options" :floating="false" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { usePodStore } from '@/stores/pod'
import InputDropdown from '@/components/Input/InputDropdown.vue'
import { computed, watch } from 'vue'
import { useGtm } from '@/composables/useGtm';

const pod = usePodStore()

const options = [
  { label: 'Kies een afstand', value: '', disabled: true },
  { label: '0 tot 20 meter', value: '0 - 20 meter' },
  { label: '20 tot 30 meter', value: '20 - 30 meter' },
  { label: '30 tot 40 meter', value: '30 - 40 meter' },
  { label: '40 tot 50 meter', value: '40 - 50 meter' },
  { label: '50+ meter', value: '50+ meter' }
]

const selected = computed(() => {
  return options.find((option) => option.value === pod.options.distance)?.label
})

watch(
  () => pod.options.distance,
  () => {
    if (pod.errors.includes('transport')) {
      pod.errors = []
    }
  }
)

useGtm().addEvent('configurator-stap-transport')
</script>
