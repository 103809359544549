<template>
  <div v-show="modalShown" @click.self="(!blocking) ? modalShown = false : null"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white w-full max-w-[90vw] lg:max-w-md p-4 flex flex-col gap-4">
      <div class="flex items-start justify-between gap-4">
        <h3>{{ title }}</h3>
        <button v-if="!blocking" @click="modalShown = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M0.75 23.249L23.25 0.749023" stroke="#333333" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M23.25 23.249L0.75 0.749023" stroke="#333333" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
      </div>

      <div v-html="content" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue'

const modalShown = ref(false)

const title = ref('')
const content = ref('')
const blocking = ref(false)

// Listen for modal events
window.addEventListener('showModal', (event: CustomEventInit) => {
  title.value = event.detail.title ?? ''
  content.value = event.detail.content ?? ''
  blocking.value = event.detail.blocking ?? false

  modalShown.value = true
})
</script>
