<template>
  <div class="category" :class="{ 'error': pod.errors.includes('foundation') }">
    <div class="category-label">
      <span>Kies de fundering</span>
    </div>
    <div class="grid grid-cols-2 gap-2 option-group">
      <button @click="pod.options.foundation = true"
        class="transition-colors aspect-video cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center justify-center gap-3 rounded border-brand-tint-lightgray"
        :class="{ 'option-selected': pod.options.foundation === true }">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
          <path
            d="M2.5 32.9998C2.5 34.5469 3.11458 36.0307 4.20854 37.1246C5.30251 38.2186 6.78624 38.8332 8.33333 38.8332C9.88043 38.8332 11.3642 38.2186 12.4581 37.1246C13.5521 36.0307 14.1667 34.5469 14.1667 32.9998C14.1667 31.4527 13.5521 29.969 12.4581 28.875C11.3642 27.7811 9.88043 27.1665 8.33333 27.1665C6.78624 27.1665 5.30251 27.7811 4.20854 28.875C3.11458 29.969 2.5 31.4527 2.5 32.9998Z"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M6.66699 33.2087C6.66699 33.6507 6.84259 34.0746 7.15515 34.3872C7.46771 34.6997 7.89163 34.8753 8.33366 34.8753C8.77569 34.8753 9.19961 34.6997 9.51217 34.3872C9.82473 34.0746 10.0003 33.6507 10.0003 33.2087C10.0003 32.9898 9.95722 32.7731 9.87346 32.5709C9.7897 32.3686 9.66693 32.1849 9.51217 32.0301C9.35741 31.8754 9.17367 31.7526 8.97146 31.6689C8.76926 31.5851 8.55253 31.542 8.33366 31.542C8.11479 31.542 7.89806 31.5851 7.69585 31.6689C7.49364 31.7526 7.30991 31.8754 7.15515 32.0301C7.00038 32.1849 6.87762 32.3686 6.79386 32.5709C6.7101 32.7731 6.66699 32.9898 6.66699 33.2087Z"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M18.333 33.2087C18.333 33.4275 18.3761 33.6443 18.4599 33.8465C18.5436 34.0487 18.6664 34.2324 18.8212 34.3872C18.9759 34.5419 19.1597 34.6647 19.3619 34.7485C19.5641 34.8322 19.7808 34.8753 19.9997 34.8753C20.2185 34.8753 20.4353 34.8322 20.6375 34.7485C20.8397 34.6647 21.0234 34.5419 21.1782 34.3872C21.3329 34.2324 21.4557 34.0487 21.5395 33.8465C21.6232 33.6443 21.6663 33.4275 21.6663 33.2087C21.6663 32.9898 21.6232 32.7731 21.5395 32.5709C21.4557 32.3686 21.3329 32.1849 21.1782 32.0301C21.0234 31.8754 20.8397 31.7526 20.6375 31.6689C20.4353 31.5851 20.2185 31.542 19.9997 31.542C19.7808 31.542 19.5641 31.5851 19.3619 31.6689C19.1597 31.7526 18.9759 31.8754 18.8212 32.0301C18.6664 32.1849 18.5436 32.3686 18.4599 32.5709C18.3761 32.7731 18.333 32.9898 18.333 33.2087Z"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M29.792 33.2087C29.792 33.6507 29.9676 34.0746 30.2801 34.3872C30.5927 34.6997 31.0166 34.8753 31.4587 34.8753C31.9007 34.8753 32.3246 34.6997 32.6372 34.3872C32.9497 34.0746 33.1253 33.6507 33.1253 33.2087C33.1253 32.7666 32.9497 32.3427 32.6372 32.0301C32.3246 31.7176 31.9007 31.542 31.4587 31.542C31.0166 31.542 30.5927 31.7176 30.2801 32.0301C29.9676 32.3427 29.792 32.7666 29.792 33.2087Z"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M2.5 32.9998V7.99984C2.5 6.45274 3.11458 4.96901 4.20854 3.87505C5.30251 2.78109 6.78624 2.1665 8.33333 2.1665C9.88043 2.1665 11.3642 2.78109 12.4581 3.87505C13.5521 4.96901 14.1667 6.45274 14.1667 7.99984V32.9998"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M14.167 33.0003C14.167 33.7664 14.3179 34.5249 14.611 35.2326C14.9042 35.9404 15.3339 36.5834 15.8755 37.1251C16.4172 37.6668 17.0603 38.0965 17.768 38.3896C18.4757 38.6828 19.2343 38.8337 20.0003 38.8337C20.7664 38.8337 21.5249 38.6828 22.2326 38.3896C22.9404 38.0965 23.5834 37.6668 24.1251 37.1251C24.6668 36.5834 25.0965 35.9404 25.3896 35.2326C25.6828 34.5249 25.8337 33.7664 25.8337 33.0003C25.8337 32.2343 25.6828 31.4757 25.3896 30.768C25.0965 30.0603 24.6668 29.4172 24.1251 28.8755C23.5834 28.3339 22.9404 27.9042 22.2326 27.611C21.5249 27.3179 20.7664 27.167 20.0003 27.167C19.2343 27.167 18.4757 27.3179 17.768 27.611C17.0603 27.9042 16.4172 28.3339 15.8755 28.8755C15.3339 29.4172 14.9042 30.0603 14.611 30.768C14.3179 31.4757 14.167 32.2343 14.167 33.0003Z"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M14.167 33.0003V8.00033C14.167 6.45323 14.7816 4.9695 15.8755 3.87554C16.9695 2.78157 18.4532 2.16699 20.0003 2.16699C21.5474 2.16699 23.0312 2.78157 24.1251 3.87554C25.2191 4.9695 25.8337 6.45323 25.8337 8.00033V33.0003"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M25.833 33.0003C25.833 34.5474 26.4476 36.0312 27.5416 37.1251C28.6355 38.2191 30.1192 38.8337 31.6663 38.8337C33.2134 38.8337 34.6972 38.2191 35.7911 37.1251C36.8851 36.0312 37.4997 34.5474 37.4997 33.0003C37.4997 31.4532 36.8851 29.9695 35.7911 28.8755C34.6972 27.7816 33.2134 27.167 31.6663 27.167C30.1192 27.167 28.6355 27.7816 27.5416 28.8755C26.4476 29.9695 25.833 31.4532 25.833 33.0003Z"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M25.833 33.0003V8.00033C25.833 6.45323 26.4476 4.9695 27.5416 3.87554C28.6355 2.78157 30.1192 2.16699 31.6663 2.16699C33.2134 2.16699 34.6972 2.78157 35.7911 3.87554C36.8851 4.9695 37.4997 6.45323 37.4997 8.00033V33.0003"
            stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <div class="flex items-center gap-1">
          <span>Schroeffundering</span>
          <ToolTip @tooltip-clicked="triggerFoundationModal(true)" />
        </div>
      </button>
      <button @click="pod.options.foundation = false"
        class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col aspect-video justify-center items-center gap-3 rounded border-brand-tint-lightgray"
        :class="{ 'option-selected': pod.options.foundation === false }">
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
          <rect x="5.25" y="4.25" width="30.5" height="31.5" rx="2.25" stroke="#333333" stroke-width="1.5" />
        </svg>
        <div class="flex items-center gap-1">
          <span>Eigen fundering</span>
          <ToolTip @tooltip-clicked="triggerFoundationModal(false)" />
        </div>
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGtm } from '@/composables/useGtm';
import { useModal } from '@/composables/useModal';
import { useModx } from '@/composables/useModx';
import { usePodStore } from '@/stores/pod'
import { onMounted, watch } from 'vue'
import ToolTip from '@/components/ToolTip.vue'

const pod = usePodStore()

const modx = useModx()

watch(
  () => pod.options.foundation,
  async () => {

    // Remove errors when model is selected
    if (pod.errors.includes('foundation')) {
      pod.errors = []
    }
  }
)

onMounted(() => {
  if (pod.options.foundation === null) {
    pod.options.foundation = true
  }
})

async function triggerFoundationModal(isSchroef: boolean) {

  if (isSchroef) {
    useModal((await modx).content['fundering-schroef'].title, (await modx).content['fundering-schroef'].content)
  } else {
    useModal((await modx).content['fundering-eigen'].title, (await modx).content['fundering-eigen'].content)
  }
}

useGtm().addEvent('configurator-stap-fundering')
</script>
