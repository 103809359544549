<template>
  <div>
    <div class="category" :class="{ 'error': pod.errors.includes('orientation') }">
      <div class="category-label">
        <span>Vorm dak</span>
      </div>
      <div class="grid grid-cols-3 gap-2 option-group">
        <label
          class="transition-colors aspect-square cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center justify-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.orientation === 'links_hoog' }">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="38" viewBox="0 0 33 38" fill="none">
            <path
              d="M31.5 34.5V11.3609C31.5 10.4865 30.932 9.71348 30.0975 9.45226L4.09749 1.31313C2.80944 0.909911 1.5 1.8721 1.5 3.22179V34.5C1.5 35.6046 2.39543 36.5 3.5 36.5H29.5C30.6046 36.5 31.5 35.6046 31.5 34.5Z"
              stroke="#333333" stroke-width="1.5" stroke-linejoin="round" />
          </svg>

          <span class="text-sm lg:text-base">Links hoog</span>
          <input type="radio" value="links_hoog" v-model="pod.options.orientation" class="hidden" />
        </label>
        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col aspect-square justify-center items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.orientation === 'plat_dak' }">
          <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
            <rect x="5.25" y="4.25" width="30.5" height="31.5" rx="2.25" stroke="#333333" stroke-width="1.5" />
          </svg>

          <span class="text-sm lg:text-base">Plat dak</span>
          <input type="radio" value="plat_dak" v-model="pod.options.orientation" class="hidden" />
        </label>

        <label
          class="transition-colors cursor-pointer aspect-square justify-center text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.orientation === 'rechts_hoog' }">
          <svg xmlns="http://www.w3.org/2000/svg" width="33" height="38" viewBox="0 0 33 38" fill="none">
            <path
              d="M1.5 34.5V11.3609C1.5 10.4865 2.06803 9.71348 2.90251 9.45226L28.9025 1.31313C30.1906 0.909911 31.5 1.8721 31.5 3.22179V34.5C31.5 35.6046 30.6046 36.5 29.5 36.5H3.5C2.39543 36.5 1.5 35.6046 1.5 34.5Z"
              stroke="#333333" stroke-width="1.5" stroke-linejoin="round" />
          </svg>

          <span class="text-sm lg:text-base">Rechts hoog</span>
          <input type="radio" value="rechts_hoog" v-model="pod.options.orientation" class="hidden" />
        </label>
      </div>
    </div>

    <div class="border-t category">
      <div class="category-label">
        <span>Stijl gevelbekleding</span>
      </div>
      <div class="grid grid-cols-2 gap-2 option-group">
        <label v-for="(p, idx) in typePlanken" :key="idx"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center justify-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.cladding_type === idx }">
          <img :src="'/swatches/' + idx + '.jpg'" class="w-16 h-auto rounded-full aspect-square" />

          <span class="text-sm lg:text-base">{{ p }}</span>
          <input type="radio" :value="idx" v-model="pod.options.cladding_type" class="hidden" />
        </label>
      </div>
    </div>

    <div class="border-t category">
      <div class="category-label">
        <span>Diepte voorportaal</span>
      </div>
      <div>
        <p class="italic">Meer binnenruimte d.m.v. het verkorten van het voorportaal</p>
      </div>
      <div class="grid gap-2 option-group">
        <div class="flex items-center w-full gap-4 lg:gap-6">
          <div class="px-[6px] py-[7px] rounded bg-brand-tint-orange text-black h-fit flex items-center w-full gap-1">
            <button v-for="(w, idx) in maxPlanken + 1" :key="idx" @click="pod.options.planken = maxPlanken - idx"
              class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2"
              :class="{ 'bg-white text-brand-primary shadow': pod.options.planken === maxPlanken - idx }">
              <span v-if="idx !== 0 && idx !== maxPlanken">
                {{ maxPlanken - idx }}
              </span>
              <span v-else-if="idx === 0">Standaard</span>
              <span v-else>Geen</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="border-t category" :class="{ 'error': pod.errors.includes('berging') }">
      <div class="category-label">
        <span>Geïntegreerde berging</span>
        <ToolTip @tooltip-clicked="toggleStorageModal()" />
      </div>
      <div class="grid grid-cols-2 gap-2 option-group">
        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.berging === false }">
          <IconStorageToggle class="w-10 h-10" :hasStorage="false" />
          <span>Geen berging</span>
          <input type="radio" :value="false" v-model="pod.options.berging" class="hidden" />
        </label>

        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          @click="pod.options.width < 5 && toggleStorageModal()" :class="{
            'option-selected': pod.options.berging === true,
            'bg-brand-tint-lightgray opacity-40': pod.options.width < 5
          }">
          <IconStorageToggle class="w-10 h-10" :hasStorage="true" />
          <span>Met berging</span>
          <input :disabled="pod.options.width < 5" type="radio" :value="true" v-model="pod.options.berging"
            class="hidden" />
        </label>
      </div>
    </div>

    <div class="category"
      v-show="pod.options.berging && (pod.options.orientation === 'plat_dak' || pod.options.podtype === 'alpha')">
      <div class="category-label">
        <span>Positie berging</span>
      </div>
      <div class="grid grid-cols-2 gap-2 option-group">
        <label v-for="position in storagePostions" :key="position.id"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.storage_mirrored === position.value }">
          <IconStorage classes="w-10 h-10" :class="{
            'rotate-180': position.rotateIcon
          }" />
          <span>{{ position.label }}</span>
          <input type="radio" :value="position.value" v-model="pod.options.storage_mirrored" class="hidden" />
        </label>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { watch, onMounted, computed } from 'vue'

import { useModal } from '@/composables/useModal'
import { useCamera } from '@/composables/useCamera'
import { usePodStore } from '@/stores/pod'
import { useModxStore } from '@/stores/modx'
import IconStorage from '@/components/Icons/IconStorage.vue'
import IconStorageToggle from '@/components/Icons/IconStorageToggle.vue'
import { useGtm } from '@/composables/useGtm';
import { useOptionValidator } from '@/composables/useOptionValidator';
import ToolTip from '@/components/ToolTip.vue'

const pod = usePodStore()
const modx = useModxStore()

async function toggleStorageModal() {
  useModal(
    (await modx).content['opmerking-berging'].title, (await modx).content['opmerking-berging'].content
  )
}

const maxPlanken = computed(() => {
  return pod.getPlanken()
});

const typePlanken = {
  normaal: 'Standaard',
  smal: 'Smal',
  breed: 'Breed',
  barcode: 'Afwisselend',
  zwart: 'Zwart'
};

const storagePostions = computed(() => {
  if (pod.options.podtype === 'alpha' && pod.options.orientation === 'rechts_hoog') {
    return [
      {
        'id': 'links_0',
        'value': true,
        'label': 'Links',
        'rotateIcon': false
      },
      {
        'id': 'links_1',
        'value': false,
        'label': 'Rechts',
        'rotateIcon': true
      }
    ]
  }

  return [
    {
      'id': 'rechts_0',
      'value': false,
      'label': 'Links',
      'rotateIcon': false
    },
    {
      'id': 'rechts_1',
      'value': true,
      'label': 'Rechts',
      'rotateIcon': true
    }
  ]
});

let lastEdited: string = '';

watch(
  () => pod.options.orientation,
  async () => {

    if (lastEdited === 'planken') {
      useCamera('vooraanzicht')
    }

    lastEdited = 'orientation'

    useOptionValidator('orientation')

    // Remove errors when model is selected
    if (pod.errors.includes('orientation')) {
      pod.errors = []
    }
  }
)

watch(
  () => pod.options.berging,
  async () => {

    if (lastEdited === 'planken') {
      useCamera('vooraanzicht')
    }

    lastEdited = 'berging'

    useOptionValidator('berging')

    if (pod.errors.includes('berging')) {
      pod.errors = []
    }
  }
)

watch(
  () => pod.options.planken,
  async () => {
    if (lastEdited !== 'planken') {
      useCamera('portaal')
    }

    lastEdited = 'planken'
  }
)


onMounted(() => {
  useCamera('vooraanzicht')
})

useGtm().addEvent('configurator-stap-orientatie')
</script>
