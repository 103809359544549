import type { DataLayerObject } from '@gtm-support/vue-gtm'

declare global {
  interface Window {
    dataLayer?: DataLayerObject[] | undefined
  }
}

export function useGtm() {
  return {
    addEvent(event: string, data: { [key: string]: string } = {}) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event,
        ...data
      })
    }
  }
}
