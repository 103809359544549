<template>
  <div>
    <div class="category">
      <div class="category-label">
        <span>Kies je afmeting</span>
      </div>
      <div class="py-8 option-group">
        <div class="flex items-end justify-between w-full gap-2">
          <div class="grow">
            <span v-text="pod.options.podtype === 'beta' ? 'Breedte' : 'Diepte'" />
            <InputDropdown v-model="pod.options.width" :options="widthOptions" />
          </div>
          <span class="pb-3">X</span>
          <div class="grow">
            <span v-text="pod.options.podtype === 'beta' ? 'Diepte' : 'Breedte'" />
            <InputDropdown v-model="pod.options.depth" :options="depthOptions" />
          </div>
        </div>
      </div>
    </div>
    <div class="category">
      <div class="py-8 option-group">
        <SizeBuitenmaat :width="pod.options.width" :depth="pod.options.depth" :type="pod.options.podtype ?? 'beta'" />
      </div>
      <div class="py-8 option-group">
        <div v-if="maatwerkText !== ''" v-html="maatwerkText" class="italic cms-content" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { usePodStore } from '@/stores/pod'
import { useModx } from '@/composables/useModx'
import { useOptions } from '@/composables/useOptions';
import { useModal } from '@/composables/useModal'
import InputDropdown from '@/components/Input/InputDropdown.vue'
import SizeBuitenmaat from '@/components/SizeBuitenmaat.vue'

import { ref, watch } from 'vue'
import { useGtm } from '@/composables/useGtm'
import { useOptionValidator } from '@/composables/useOptionValidator'

const pod = usePodStore()
const modx = useModx()

interface Option {
  label: string;
  value: any;
}

// Create a type with a label and a value
const widthOptions = ref<Option[]>([])
const depthOptions = ref<Option[]>([])

useOptions().then((optionStore) => {
  if (pod.options.podtype === null) return

  const models = optionStore.getModels().filter((model) => pod.options.podtype && model.product.toLowerCase().includes(pod.options.podtype))

  models.forEach((model) => {
    let product = model.product.toLowerCase().replace(pod.options.podtype ?? '', '').replace('met berging', '').trim()

    // Explode the array on the x
    let [width, depth] = product.split('x')


    if (pod.options.podtype !== 'beta') {
      [width, depth] = [depth, width]
    }

    // Check if the width is already in the array
    if (!widthOptions.value.find((option) => option.value === parseFloat(width.replace(',', '.')))) {
      widthOptions.value.push({ label: `${width.replace('m', ' m')}`, value: parseFloat(width.replace(',', '.')) })
    }

    // Check if the depth is already in the array
    if (!depthOptions.value.find((option) => option.value === parseFloat(depth.replace(',', '.')))) {
      depthOptions.value.push({ label: `${depth.replace('m', ' m')}`, value: parseFloat(depth.replace(',', '.')) })
    }
  })

  // Sort the options
  widthOptions.value.sort((a, b) => a.value - b.value)
  depthOptions.value.sort((a, b) => a.value - b.value)
})

const maatwerkText = ref('')

async function getMaatwerkText() {
  let content = (await modx)?.content

  maatwerkText.value = content['maatwerk-contact']?.content ?? ''
}

getMaatwerkText()

// Watch the depth
watch(
  () => pod.options.depth,
  async (depth) => {
    if (depth === 3.9) {
      let content = (await modx)?.content['opmerking-3,9m']

      setTimeout(() => {
        useModal(content?.title, content?.content)
      }, 1_000)

    }

    useOptionValidator('depth')
  }
)

watch(
  () => pod.options.width,
  async () => {
    useOptionValidator('width')
  }
)

useGtm().addEvent('configurator-stap-afmetingen')
</script>
