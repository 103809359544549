import { defineStore } from 'pinia'
import { ref } from 'vue'

type ContentItem = {
  [key: string]: {
    title: string
    content: string
  }
}

export const useModxStore = defineStore('modx', () => {
  const version = ref(<string>'')
  const content = ref(<ContentItem>{})

  async function fetchData() {
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 2_000)

    try {
      // Get data from Modx
      const res = await fetch(import.meta.env.VITE_API_URL + '/rest/text', {
        headers: {
          Accept: 'application/json',
          'content-type': 'application/json'
        },
        signal: controller.signal
      })

      if (res.ok) {
        const data = await res.json()

        if (data.success === false) {
          console.error('Error fetching Modx data')
          return
        }

        version.value = data.message.version as string
        content.value = data.message.content as ContentItem
      } else {
        console.error('Error fetching Modx data')
      }
    } catch (error) {
      console.error('Timeout fetching Modx data')
    } finally {
      clearTimeout(timeoutId)
    }
  }

  return {
    version,
    content,
    fetchData
  }
})
