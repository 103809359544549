<template>
  <div>
    <div class="category" :class="{ 'error': pod.errors.includes('door') }">
      <div class="category-label">
        <span>Kies je type deur</span>
      </div>
      <div class="grid grid-cols-2 gap-2 option-group">
        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.door_type === 'enkeldraai' }">
          <svg xmlns="http://www.w3.org/2000/svg" width="31" height="39" viewBox="0 0 31 39" fill="none">
            <path
              d="M11.2059 20.1006C10.8743 20.1006 10.6055 19.832 10.6055 19.5002C10.6055 19.1687 10.8743 18.8999 11.2059 18.8999"
              stroke="#333333" stroke-width="1.5" />
            <path
              d="M11.2059 20.1006C11.5374 20.1006 11.8062 19.832 11.8062 19.5002C11.8062 19.1687 11.5374 18.8999 11.2059 18.8999"
              stroke="#333333" stroke-width="1.5" />
            <path
              d="M23 5.5H28.25C28.7141 5.5 29.1593 5.75462 29.4875 6.20783C29.8157 6.66103 30 7.27573 30 7.91667V32.0833C30 32.7242 29.8157 33.339 29.4875 33.7922C29.1593 34.2454 28.7141 34.5 28.25 34.5H23"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M16.4329 37.4843L2.02466 35.4992C1.73763 35.4566 1.4757 35.3116 1.28735 35.0908C1.09899 34.87 0.996948 34.5886 1.00007 34.2985V6.23434C1.00468 5.96391 1.10045 5.70295 1.27189 5.49374C1.44332 5.28453 1.68036 5.13934 1.94461 5.08168L16.3528 1.52764C16.5278 1.48944 16.709 1.49085 16.8834 1.53177C17.0577 1.57268 17.2207 1.65207 17.3604 1.76411C17.5 1.87616 17.6129 2.01802 17.6907 2.17931C17.7684 2.34062 17.809 2.51726 17.8097 2.69631V36.3157C17.8097 36.4875 17.7722 36.6572 17.7002 36.8131C17.628 36.969 17.5226 37.1073 17.3917 37.2184C17.2607 37.3297 17.1072 37.411 16.9416 37.457C16.7759 37.5028 16.6024 37.5122 16.4329 37.4843Z"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span>Enkele draaideur</span>
          <input type="radio" value="enkeldraai" v-model="pod.options.door_type" class="hidden" />
        </label>

        <label v-if="availableDoors?.find((door) => door.optie === 'Dubbele openslaande draaideuren')"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.door_type === 'dubbeldraai' }">
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
            <path
              d="M10.9219 19.5838C11.2442 19.5838 11.5055 19.3227 11.5055 19.0002C11.5055 18.6778 11.2442 18.4165 10.9219 18.4165"
              stroke="#333333" stroke-width="1.5" />
            <path
              d="M10.9224 19.5838C10.6 19.5838 10.3387 19.3227 10.3387 19.0002C10.3387 18.6778 10.6 18.4165 10.9224 18.4165"
              stroke="#333333" stroke-width="1.5" />
            <path
              d="M16.0042 36.4848L1.99619 34.5548C1.71714 34.5134 1.46249 34.3724 1.27937 34.1577C1.09624 33.9431 0.997033 33.6695 1.00007 33.3874V6.10283C1.00455 5.83992 1.09766 5.5862 1.26434 5.3828C1.431 5.17941 1.66147 5.03825 1.91837 4.98219L15.9264 1.52687C16.0965 1.48973 16.2727 1.49111 16.4422 1.53089C16.6117 1.57066 16.7701 1.64785 16.906 1.75678C17.0417 1.86571 17.1514 2.00363 17.2271 2.16044C17.3026 2.31727 17.3421 2.48901 17.3427 2.66308V35.3486C17.3427 35.5156 17.3063 35.6806 17.2363 35.8322C17.1661 35.9838 17.0637 36.1182 16.9363 36.2263C16.809 36.3344 16.6598 36.4135 16.4988 36.4582C16.3377 36.5027 16.169 36.5119 16.0042 36.4848Z"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M27.0311 19.5838C26.7088 19.5838 26.4475 19.3227 26.4475 19.0002C26.4475 18.6778 26.7088 18.4165 27.0311 18.4165"
              stroke="#333333" stroke-width="1.5" />
            <path
              d="M27.0316 19.5838C27.354 19.5838 27.6153 19.3227 27.6153 19.0002C27.6153 18.6778 27.354 18.4165 27.0316 18.4165"
              stroke="#333333" stroke-width="1.5" />
            <path
              d="M21.9498 36.4848L35.9578 34.5548C36.2368 34.5134 36.4915 34.3724 36.6746 34.1577C36.8577 33.9431 36.9569 33.6695 36.9539 33.3874V6.10283C36.9494 5.83992 36.8563 5.5862 36.6896 5.3828C36.523 5.17941 36.2925 5.03825 36.0356 4.98219L22.0276 1.52687C21.8575 1.48973 21.6813 1.49111 21.5118 1.53089C21.3423 1.57066 21.1839 1.64785 21.048 1.75678C20.9123 1.86571 20.8025 2.00363 20.7269 2.16044C20.6514 2.31727 20.6119 2.48901 20.6113 2.66308V35.3486C20.6113 35.5156 20.6477 35.6806 20.7177 35.8322C20.7879 35.9838 20.8903 36.1182 21.0176 36.2263C21.145 36.3344 21.2942 36.4135 21.4552 36.4582C21.6163 36.5027 21.785 36.5119 21.9498 36.4848Z"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span>Dubbele draaideur</span>
          <input type="radio" value="dubbeldraai" v-model="pod.options.door_type" class="hidden" />
        </label>

        <label v-if="availableDoors?.find((door) => door.optie === 'Enkele schuifpui')"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.door_type === 'enkelschuif' }">
          <svg xmlns="http://www.w3.org/2000/svg" width="37" height="38" viewBox="0 0 37 38" fill="none">
            <path
              d="M31.6667 1.5H14.3333C13.9797 1.5 13.6405 1.66389 13.3905 1.95561C13.1405 2.24734 13 2.64299 13 3.05556V36.5H33V3.05556C33 2.64299 32.8595 2.24734 32.6095 1.95561C32.3594 1.66389 32.0203 1.5 31.6667 1.5Z"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M36 36.5H3.33334" stroke="#333333" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <line x1="27.3389" y1="17.1333" x2="27.3389" y2="22.7333" stroke="#333333" stroke-width="1.5" />
            <path
              d="M7.80556 36.5L2.70139 36.5C2.25018 36.5 1.81735 36.1927 1.49828 35.6457C1.17921 35.0988 0.999999 34.3569 0.999999 33.5833L1 4.41667C1 3.64317 1.17921 2.90117 1.49828 2.3542C1.81735 1.80722 2.25018 1.5 2.70139 1.5L7.80556 1.5"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span>Enkele schuifpui</span>
          <input type="radio" value="enkelschuif" v-model="pod.options.door_type" class="hidden" />
        </label>

        <label v-if="availableDoors?.find((door) => door.optie === 'Dubbele schuifpui')"
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.door_type === 'dubbelschuif' }">
          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="38" viewBox="0 0 40 38" fill="none">
            <path
              d="M14.0667 1.5H1.93333C1.68581 1.5 1.44837 1.66389 1.27337 1.95561C1.09837 2.24734 1 2.64299 1 3.05556V34.5C1 35.6046 1.89543 36.5 3 36.5H13C14.1046 36.5 15 35.6046 15 34.5V3.05556C15 2.64299 14.9017 2.24734 14.7266 1.95561C14.5516 1.66389 14.3142 1.5 14.0667 1.5Z"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <line x1="10.4707" y1="17.1333" x2="10.4707" y2="22.7333" stroke="#333333" stroke-width="1.5" />
            <path
              d="M25.9333 1.5H38.0667C38.3142 1.5 38.5516 1.66389 38.7266 1.95561C38.9016 2.24734 39 2.64299 39 3.05556V34.5C39 35.6046 38.1046 36.5 37 36.5H27C25.8954 36.5 25 35.6046 25 34.5V3.05556C25 2.64299 25.0983 2.24734 25.2734 1.95561C25.4484 1.66389 25.6858 1.5 25.9333 1.5Z"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <line y1="-0.75" x2="5.6" y2="-0.75" transform="matrix(3.59976e-08 1 1 -5.30781e-08 30.2793 17.1333)"
              stroke="#333333" stroke-width="1.5" />
          </svg>
          <span>Dubbele schuifpui</span>
          <input type="radio" value="dubbelschuif" v-model="pod.options.door_type" class="hidden" />
        </label>
      </div>
    </div>

    <div class="category" :class="{ 'error': pod.errors.includes('door_mirrored') }">
      <div class="category-label">
        <span>Positie deur</span>
      </div>
      <div class="grid grid-cols-2 gap-2 option-group">
        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.door_mirrored === false }">
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="40" viewBox="0 0 38 40" fill="none">
            <path
              d="M8.16667 1.25H29.8333C30.2753 1.25 30.6993 1.42559 31.0118 1.73815C31.3243 2.05072 31.5 2.47463 31.5 2.91667V38.75H6.5V2.91667C6.5 2.47463 6.6756 2.05072 6.98815 1.73815C7.30072 1.42559 7.72463 1.25 8.16667 1.25Z"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.5 38.75H36.5" stroke="#333333" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M25.25 20.625C24.9048 20.625 24.625 20.3452 24.625 20C24.625 19.6548 24.9048 19.375 25.25 19.375"
              stroke="#333333" stroke-width="1.5" />
            <path d="M25.25 20.625C25.5952 20.625 25.875 20.3452 25.875 20C25.875 19.6548 25.5952 19.375 25.25 19.375"
              stroke="#333333" stroke-width="1.5" />
          </svg>
          <span>Links</span>
          <input type="radio" :value="false" v-model="pod.options.door_mirrored" class="hidden" />
        </label>

        <label
          class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col items-center gap-3 rounded border-brand-tint-lightgray"
          :class="{ 'option-selected': pod.options.door_mirrored === true }">
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="40" viewBox="0 0 38 40" fill="none">
            <path
              d="M29.8333 1.25H8.16667C7.72467 1.25 7.30067 1.42559 6.98817 1.73815C6.67567 2.05072 6.5 2.47463 6.5 2.91667V38.75H31.5V2.91667C31.5 2.47463 31.3244 2.05072 31.0118 1.73815C30.6993 1.42559 30.2754 1.25 29.8333 1.25Z"
              stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M36.5 38.75H1.5" stroke="#333333" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M12.75 20.625C13.0952 20.625 13.375 20.3452 13.375 20C13.375 19.6548 13.0952 19.375 12.75 19.375"
              stroke="#333333" stroke-width="1.5" />
            <path d="M12.75 20.625C12.4048 20.625 12.125 20.3452 12.125 20C12.125 19.6548 12.4048 19.375 12.75 19.375"
              stroke="#333333" stroke-width="1.5" />
          </svg>
          <span>Rechts</span>
          <input type="radio" :value="true" v-model="pod.options.door_mirrored" class="hidden" />
        </label>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, watch, ref } from 'vue'
import { usePodStore } from '@/stores/pod'
import { useCamera } from '@/composables/useCamera';
import { useOptions } from '@/composables/useOptions';
import { useGtm } from '@/composables/useGtm';

const pod = usePodStore()

const availableDoors = ref(<{ hs_id: number; optie: string; }[] | null>null)

useOptions().then((optionStore) => {
  const model = optionStore.getModel(pod.options)
  if (model === null) return

  // Get available doors
  availableDoors.value = optionStore.getAvailableDoors(pod.options);
})

function removeError(error: string) {
  if (pod.errors.includes(error)) {
    pod.errors = pod.errors.filter((e) => e !== error)
  }
}

watch(
  () => pod.options.door_type,
  () => {

    // Remove errors when model is selected
    removeError('door')
  }
)

watch(
  () => pod.options.door_mirrored,
  () => {
    removeError('door_mirrored')
    useCamera(pod.options.door_mirrored ? 'deur_rechts' : 'deur_links')
  }
)

onMounted(() => {
  useCamera(pod.options.door_mirrored ? 'deur_rechts' : 'deur_links')
})

useGtm().addEvent('configurator-stap-deuren')
</script>
