<template>
  <div>
    <div class="category">
      <div class="category-label">
        <span>Opmerkingen</span>
      </div>
      <div>
        <p>Heb je nog speciale verzoeken of aanvullende wensen? Geef dat hieronder aan.</p>
      </div>

      <div class="py-8 option-group">
        <textarea placeholder="Omschrijf je verzoek" rows="6" v-model="pod.options.comment" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGtm } from '@/composables/useGtm';
import { usePodStore } from '@/stores/pod'

const pod = usePodStore()

useGtm().addEvent('configurator-stap-opmerkingen')
</script>
