<template>
  <div
    class="relative z-10 flex items-center w-full px-4 py-2 overflow-x-auto bg-white shadow lg:p-4 hide-scrollbar snap-x">
    <button v-for="(s, idx) in pod.steps" :key="idx" @click="pod.setStep(idx)"
      class="flex items-center gap-2 px-3 py-2 shrink-0 snap-start step-indicator"
      :class="{ selected: idx === pod.currentStep }">
      <div class="grid w-6 h-6 transition-all rounded-full place-items-center" :class="{
      'text-brand-green bg-brand-tint-lightgreen': idx <= pod.currentStep,
      'text-brand-gray bg-brand-tint-lightgray': idx > pod.currentStep
    }">
        <span v-show="idx >= pod.currentStep" v-text="idx + 1"></span>
        <IconCheck v-show="idx < pod.currentStep" classes="w-[10px] h-[10px]" />
      </div>
      <span v-text="s" class="transition-colors" :class="{
      'font-bold text-brand-secondary': idx === pod.currentStep,
      'text-brand-secondary': idx < pod.currentStep,
      'text-brand-gray': idx > pod.currentStep
    }"></span>
    </button>
  </div>
</template>
<script setup lang="ts">
import { usePodStore } from '@/stores/pod'
import IconCheck from './Icons/IconCheck.vue'
import { onMounted } from 'vue'

const pod = usePodStore()

function scrollToCurrentStep() {
  // Await next tick
  setTimeout(() => {
    const step = document.querySelector('.selected')
    if (step) {
      step.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, 200)
}

// On Mounted, make sure the current step is visible
onMounted(() => {
  scrollToCurrentStep()
})
</script>
