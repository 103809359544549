<template>
  <div class="relative flex flex-col max-h-[100dvh] min-h-[100dvh] bg-brand-tint-lightgreen text-brand-black">
    <!-- Top bar -->
    <div class="px-4 py-3 lg:py-1 bg-brand-black flex items-center gap-[10px] text-white text-sm">
      <img src="@/assets/images/logo-mobile.svg" alt="OPUS" class="lg:hidden" />
      <img src="@/assets/images/logo-desktop.svg" alt="OPUS" class="hidden lg:block" />
      <div class="flex items-center justify-center w-full gap-2 lg:justify-start">
        <span>Beoordeeld met 9.8</span>
        <span class="text-xl leading-base">&middot;</span>
        <span>Al {{ podCount }} pods geplaatst</span>
      </div>
      <button @click="showHelpModal = true" class="hidden ml-auto p-[6px] bg-brand-secondary">
        Hulp nodig?
      </button>
    </div>

    <!-- OptIn container -->
    <OptInContainer v-if="!podStore.started" />

    <ConfigurationContainer :preview="false" v-if="podStore.started" />

    <!-- Help modal -->
    <div v-if="showHelpModal" @click.self="showHelpModal = false"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div class="bg-white w-full max-w-[90vw] lg:max-w-md p-4 flex flex-col gap-4">
        <div class="flex items-start justify-between">
          <img src="@/assets/images/staff.png" loading="lazy" />
          <button @click="showHelpModal = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M0.75 23.249L23.25 0.749023" stroke="#333333" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M23.25 23.249L0.75 0.749023" stroke="#333333" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
        </div>

        <div>
          <h3>Hulp nodig met je configuratie?</h3>
          <p>Ons team staat klaar om al jouw vragen te beantwoorden. We helpen je graag.</p>
        </div>

        <div>
          <textarea rows="3" placeholder="Je vraag of opmerking"
            class="w-full p-3 border rounded resize-none border-brand-gray"></textarea>
          <button class="w-full mt-3 button-orange-contained">Verstuur</button>
        </div>

        <div class="py-3 border-y border-brand-tint-lightgreen">
          <p>
            <span class="font-bold">Bel: </span><a href="#" class="text-brand-primary">+31 (0)73 23 40 023</a><br />
            <span class="font-bold">Whatsapp: </span><a href="#" class="text-brand-primary">+31 (0)73 23 40
              023</a><br />
            <span class="font-bold">Mail: </span><a href="#" class="text-brand-primary">info@opuspod.nl</a><br />
          </p>
        </div>
      </div>
    </div>
    <DefaultModal />
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import OptInContainer from '@/components/OptInContainer.vue'
import ConfigurationContainer from '@/components/ConfigurationContainer.vue'
import DefaultModal from '@/components/DefaultModal.vue'
import { usePodStore } from '@/stores/pod'
import { useModx } from '@/composables/useModx'

const modx = useModx()

const podStore = usePodStore()

const showHelpModal = ref(false)

const podCount = ref('200+')

async function getPodCount() {
  try {
    const res: any = await fetch('https://mijn.opuspod.nl/api/cms/pod_count')

    if (!res.ok) {
      return
    }

    const data = await res.json()

    podCount.value = data?.count ?? '200+'
  } catch (e) {
    return;
  }
}

function setContactData(data: string | null) {
  if (!data) {
    return
  }

  let uData

  try {
    uData = JSON.parse(atob(atob(data)))
  } catch (e) {
    return
  }

  if (!uData) {
    return
  }

  podStore.optInForm.firstName = uData.firstname
  podStore.optInForm.lastName = uData.lastname
  podStore.optInForm.email = uData.email

  podStore.started = true
}

function resumeConfiguration(hash: string | null) {
  if (hash === null) {
    return
  }

  podStore.resumeHash(hash);
}

async function prefetchAssets() {
  const version = (await modx).version !== '' ? `?v=${(await modx).version}` : ''

  // Add a prefetch link to the dynamic configurator script
  const link = document.createElement('link')
  link.rel = 'prefetch'
  link.href = import.meta.env.VITE_API_URL + '/assets/js/dynamic_configurator.js' + version
  document.head.appendChild(link)
}

onMounted(() => {
  getPodCount()

  window.addEventListener('load', prefetchAssets)
  // Check if the u-parameter is present in the URL
  const urlParams = new URLSearchParams(window.location.search)

  // Check for user-data
  if (urlParams.has('u')) {
    setContactData(urlParams.get('u'))
  }

  // Check for user-hash
  if (urlParams.has('hash') && urlParams.has('u')) {
    const hash = urlParams.get('hash')
    resumeConfiguration(hash)
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('load', prefetchAssets)
})
</script>
