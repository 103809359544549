<template>
  <div class="flex flex-col">
    <div class="p-4 m-4 bg-brand-tint-orange">
      <h2 class="pb-2">Je offerte aanvraag is verstuurd!</h2>
      <p>
        We hebben je aanvraag in goede orde ontvangen en zullen snel je offerte toesturen. Indien we
        vragen hebben nemen we nog even telefonisch contact op.
      </p>
    </div>

    <div class="flex flex-col gap-5 px-4 py-10 bg-brand-tint-lightgreen">
      <h2>Ontdek de pods in het echt</h2>
      <p>
        Onze pods zijn in het echt nog mooier dan in de 3D omgeving. Kom onze pods ervaren en
        bekijken in onze OPUS productiehal.
      </p>
      <button @click="modal = !modal" class="button-blue-contained w-fit">Maak een afspraak</button>
    </div>

    <div class="flex flex-col px-4 mt-4">
      <!-- Share -->
      <h4 class="text-brand-secondary">Deel jouw gemaakte 3D-configuratie</h4>
      <ShareBar />
    </div>
    <CalendlyModal v-model="modal" />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import ShareBar from '@/components/ShareBar.vue'
import CalendlyModal from '@/components/CalendlyModal.vue'
import { useGtm } from '@/composables/useGtm';

const modal = ref(false)

useGtm().addEvent('offerte-aangevraagd')
</script>
