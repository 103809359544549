<template>
    <div v-show="open" @click="open = !open"
        class="fixed inset-0 z-20 flex w-full h-full p-4 lg:grid place-items-center bg-brand-black/40 backdrop-blur">
        <div class="flex flex-col w-full max-w-xl gap-4 p-4 bg-white branded-scroll">
            <div class="flex flex-col h-full gap-6">
                <iframe
                    src="https://calendly.com/opuspod/bezichtiging?embed_domain=opuspod.nl&embed_type=Inline&hide_event_type_details=1&hide_gdpr_banner=1&primary_color=FF6C00"
                    frameborder="0" loading="lazy" class="w-full h-full min-h-[80dvh]" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const open = defineModel()
</script>
