import { useOptionStore } from '@/stores/option'

export async function useOptions() {
  const optionStore = useOptionStore()

  // If options is an empty object, fetch the data
  if (Object.keys(optionStore.options).length === 0) {
    await optionStore.fetchData()
  }

  return optionStore
}
