<template>
  <div v-show="!pod.started" class="relative flex-grow h-full lg:static">
    <img src="@/assets/images/landing_2.jpg" class="w-full aspect-[353/226] object-cover lg:hidden" />

    <!-- Modal wrapper -->
    <div class="absolute inset-0 z-10 grid w-full h-full px-4 place-items-center" id="optInWrapper"
      :class="{ 'backdrop-blur': pod.showConfirmation }">
      <div class="pt-56 lg:pt-0">
        <div class="p-5 bg-white rounded shadow">
          <!-- OptIn Modal -->
          <div v-show="!pod.started && !pod.showConfirmation" class="flex flex-col gap-5">
            <h1 class="text-brand-secondary h2">Aan de slag</h1>
            <p>
              Vul hieronder je gegevens in om je wensen op te slaan en de configurator te gebruiken.
            </p>
            <div id="hubspotForm" ref="hubspotForm"></div>
          </div>

          <!-- Confimation modal -->
          <EmailValidationModal v-if="pod.showConfirmation" />
        </div>
        <div class="pt-6">
          <div class="flex flex-col gap-2">
            <div v-for="u in usps" :key="u" class="flex items-center gap-2">
              <IconCheck classes="text-brand-tint-green w-4 h-4" />
              <span class="font-bold text-brand-secondary lg:text-white">{{ u }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePodStore } from '@/stores/pod'
import IconCheck from '@/components/Icons/IconCheck.vue'
import EmailValidationModal from '@/components/EmailValidationModal.vue'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { useHubspotForm } from '@/composables/useHubspotForm'

import { useGtm } from '@/composables/useGtm'

const hsFormId = import.meta.env.VITE_ENV === 'production_local' ? '4f99fb47-7a93-465f-9b4c-a6049b05c848' : 'fa429b35-eea4-4c8b-9883-f392a616f607'

const usps = [
  'Kies je model en pas het aan',
  'Precies naar jouw wens',
  'Ontvang een offerte in je mail'
]

const pod = usePodStore()

const hubspotForm = ref()

function hsEventListener(event: any) {
  if (event.data.id !== hsFormId) {
    return;
  }

  if (event.data.type !== 'hsFormCallback') {
    return
  }

  if (event.data.eventName !== 'onFormSubmitted') {
    return
  }

  // Form is submitted
  if (import.meta.env.VITE_ENV === 'production_local') {
    pod.showConfirmation = false
    pod.started = true
  } else {
    pod.showConfirmation = true
  }

  useGtm().addEvent('configurator-opt-in-submit')
}

// Function to add additional validation to the form
function handleFormSubmit(event: Event) {
  let invalid = false;

  if (hubspotForm.value.querySelector('input[name=firstname]').value.length < 2) {
    hubspotForm.value.querySelector('input[name=firstname]').classList.add('error')
    invalid = true
  }

  if (hubspotForm.value.querySelector('input[name=lastname]').value.length < 2) {
    hubspotForm.value.querySelector('input[name=lastname]').classList.add('error')
    invalid = true
  }


  if (invalid) {
    event.preventDefault()
    return
  }
}

onMounted(async () => {
  await useHubspotForm().then(() => {
    window.hbspt.forms.create({
      region: 'eu1',
      portalId: '25454358',
      formId: hsFormId,
      target: '#hubspotForm',
      onFormReady: function () {
        // Add an event listener to the submit button
        hubspotForm.value.querySelector('input[type=submit]').addEventListener('click', handleFormSubmit)
      },
      onFormSubmit: function () {
        let firstname = hubspotForm.value.querySelector('input[name=firstname]')

        // Check if the element is found
        if (!firstname) {
          return
        }

        firstname.value = firstname.value.trim()

        // Check if firstname contains an uppercase letter
        if (!firstname.value.match(/[A-Z]/)) {
          firstname.value = firstname.value.charAt(0).toUpperCase() + firstname.value.slice(1)
        }

        pod.optInForm.firstName = firstname.value

        let lastname = hubspotForm.value.querySelector('input[name=lastname]')

        if (!lastname) {
          return
        }

        lastname.value = lastname.value.trim()

        // Count captial letters in lastname
        let capitalLetters = (lastname.value.match(/[A-Z]/g) || []).length

        // Check if lastname contains an uppercase letter
        if (
          !lastname.value.match(/[A-Z]/) ||
          capitalLetters > 4
        ) {

          if (capitalLetters > 4) {
            lastname.value = lastname.value.toLowerCase()
          }

          let lastnameArray = lastname.value.split(' ')

          if (lastnameArray.length > 1) {
            let i = lastnameArray.length - 1
            for (i; i > 0; i--) {
              if (i === lastnameArray.length - 1) { // Last element
                // Capitalize first letter
                lastnameArray[i] = lastnameArray[i].charAt(0).toUpperCase() + lastnameArray[i].slice(1)
              } else {
                // Check if the word does not start with v or d
                if (lastnameArray[i].charAt(0).toLowerCase() !== 'v' && lastnameArray[i].charAt(0).toLowerCase() !== 'd') {
                  // Capitalize first letter
                  lastnameArray[i] = lastnameArray[i].charAt(0).toUpperCase() + lastnameArray[i].slice(1)
                }
              }
            }

            lastname.value = lastnameArray.join(' ')
          } else {
            lastname.value = lastname.value.charAt(0).toUpperCase() + lastname.value.slice(1)
          }
        }

        pod.optInForm.lastName = lastname.value

        pod.optInForm.email = hubspotForm.value.querySelector('input[name=email]').value

        if (hubspotForm.value.querySelector('input[name=phone]')) {
          pod.quoteForm.telefoon = hubspotForm.value.querySelector('input[name=phone]').value
        }

        hubspotForm.value.querySelector('input[name=configurator_uid]').value = btoa(
          btoa(
            '{"email":"' +
            hubspotForm.value.querySelector('input[name=email]').value +
            '","firstname":"' +
            hubspotForm.value.querySelector('input[name=firstname]').value +
            '","lastname":"' +
            hubspotForm.value.querySelector('input[name=lastname]').value +
            '"}'
          )
        ).replace(/=/g, '')
      }
    })
  })

  // Add event listener to the form
  window.addEventListener('message', hsEventListener, false)
})

onBeforeUnmount(() => {
  window.removeEventListener('message', hsEventListener)
})
</script>
