<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7.75"
      y="4.25"
      width="8.5"
      height="32.5"
      rx="2.25"
      stroke="#BDBDBD"
      stroke-width="1.5"
    />
    <rect
      x="24.75"
      y="4.25"
      width="8.5"
      height="32.5"
      rx="2.25"
      stroke="#BDBDBD"
      stroke-width="1.5"
    />
    <path
      d="M2 38.5L38 2.5"
      stroke="#333333"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38 38.5L2 2.5"
      stroke="#333333"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
