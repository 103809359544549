<template>
  <div class="flex flex-col gap-4 p-4 rounded bg-brand-tint-lightgreen">
    <span class="text-brand-green">Buitenmaat</span>
    <div class="relative flex justify-center w-full pb-6 text-brand-shade-gray">
      <div :style="'aspect-ratio: ' + aspectRatio + ';'"
        class="relative flex items-center justify-center p-3 transition-all duration-1000 min-h-20 h-fit outline-1 -outline-offset-1 outline-dashed outline-brand-shade-gray">
        <span v-html="size" class="shrink-0"></span>
        <div class="absolute top-0 -right-[5rem] flex flex-col justify-center h-full">
          <span class="block">{{ (type === 'beta' ? depth : width).toFixed(1).replace('.', ',') }} meter</span>
        </div>
        <div class="absolute left-0 flex justify-center w-full -bottom-[2rem]">
          <span class="shrink-0">{{ (type === 'beta' ? width : depth).toFixed(1).replace('.', ',') }} meter</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
  width: {
    type: Number,
    required: true
  },
  depth: {
    type: Number,
    required: true
  },
  type: {
    type: String,
    required: true
  }
})

const size = computed(() => (props.width * props.depth).toFixed(1).replace('.', ',') + ' m<sup>2</sup>')

// Calculate the aspect-ratio
const aspectRatio = computed(() => {
  const width = props.width
  const depth = props.depth
  const ratio = props.type === 'beta' ? width / depth : depth / width
  return ratio
})
</script>
