<template>
  <div class="category" :class="{ 'error': pod.errors.includes('model') }">
    <div class="category-label">
      <span>Kies je model</span>
    </div>
    <div class="grid grid-cols-2 gap-2 option-group">
      <label v-for="o in modelOptions" :key="o.value"
        class="transition-colors cursor-pointer text-left p-3 border-[1.5px] flex flex-col gap-3 rounded border-brand-tint-lightgray"
        :class="{ 'option-selected': pod.options.podtype === o.value }">
        <div class="flex justify-center w-full">
          <img :src="o.image" loading="lazy" />
        </div>
        <span class="font-bold">{{ o.name }}</span>
        <ul class="pl-4 list-disc">
          <li v-for="(u, idx) in o.usps" :key="idx" v-text="u" />
        </ul>
        <input type="radio" :value="o.value" v-model="pod.options.podtype" class="hidden" />
      </label>
    </div>
  </div>
</template>
<script setup lang="ts">
import { watch } from 'vue'
import { usePodStore } from '@/stores/pod'
import { useGtm } from '@/composables/useGtm';
import { useOptionValidator } from '@/composables/useOptionValidator';

const pod = usePodStore()

const modelOptions = [
  {
    name: 'Model Alpha',
    usps: [
      'Dieper dan breed',
      'Deur aan korte zijde',
      'Hoge ramen aan zijkant'
    ],
    image: '/alpha.jpg',
    value: 'alpha'
  },
  {
    name: 'Model Beta',
    usps: [
      'Breder dan diep',
      'Deur aan lange zijde',
      'Grote glazen voorpui'
    ],
    image: '/beta.jpg',
    value: 'beta'
  }
]

// Watch the model change to set the default amount of windows
watch(
  () => pod.options.podtype,
  async () => {

    // Remove errors when model is selected
    if (pod.errors.includes('model')) {
      pod.errors = []
    }

    useOptionValidator('podtype');
  }
)

useGtm().addEvent('configurator-stap-modelkeuze')
</script>
