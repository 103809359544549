<template>
  <div v-if="initialRequest">
    <!-- LoadingScreen -->
    <div class="absolute inset-0 grid w-full h-full bg-gray-100 place-items-center">
      <div class="flex flex-col items-center w-full max-w-[60vw] lg:max-w-lg">
        <img src="@/assets/images/logo-loading.svg" alt="OPUS" />
        <!-- Loading bar -->
        <div role="status">
          <svg aria-hidden="true"
            class="w-8 h-8 text-gray-200 duration-1000 ease-in-out animate-spin dark:text-gray-300 fill-brand-green"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col gap-5">
    <h2 class="text-brand-secondary">Bevestig je e-mailadres</h2>
    <p>
      We hebben je zojuist een automatische e-mail gestuurd met daarin jouw unieke link. Klik op de
      knop 'bevestigen' in de mail, om verder te gaan.
    </p>

    <p>
      Geen mail ontvangen? Controleer je 'ongewenst/spam inbox' of
      <a class="underline text-brand-primary" href="https://opuspod.nl/contact/" target="_blank">stuur ons een
        bericht</a>.
    </p>

    <!-- <div class="grid w-full grid-cols-3 gap-3">
      <button class="button-email">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path
            d="M12.0733 9.83338C12.0791 9.33224 12.2118 8.84073 12.4589 8.40472C12.7061 7.96872 13.0596 7.60243 13.4866 7.34005C13.2148 6.95365 12.8574 6.63528 12.4422 6.40978C12.0271 6.18428 11.5654 6.05774 11.0933 6.04005C10.0733 5.94005 9.09331 6.64005 8.58664 6.64005C8.07998 6.64005 7.25331 6.05338 6.42664 6.06672C5.87103 6.08149 5.32879 6.24042 4.85312 6.52793C4.37744 6.81544 3.98466 7.22165 3.71331 7.70672C2.55998 9.70672 3.41998 12.6801 4.54664 14.3067C5.09331 15.1001 5.75331 15.9934 6.61331 15.9601C7.47331 15.9267 7.75998 15.4267 8.75998 15.4267C9.75998 15.4267 10.0466 15.9601 10.92 15.9467C11.7933 15.9334 12.38 15.1334 12.92 14.3401C13.3106 13.7734 13.6182 13.1538 13.8333 12.5001C13.3105 12.2775 12.8648 11.9059 12.5518 11.4317C12.2388 10.9575 12.0724 10.4016 12.0733 9.83338Z"
            stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M10.7066 3.40652C11.2695 2.74135 11.5494 1.88224 11.4866 1.01318C10.6327 1.093 9.84233 1.49891 9.27997 2.14652C8.99737 2.46552 8.78252 2.8386 8.64844 3.24313C8.51436 3.64766 8.46385 4.07521 8.49997 4.49985C8.92697 4.49885 9.34803 4.39974 9.73064 4.21017C10.1132 4.0206 10.4472 3.74564 10.7066 3.40652Z"
            stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg><span>Mail</span>
      </button>
      <button class="button-email">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
          <path
            d="M14.8334 8.05984V1.33317C14.8334 1.15636 14.7632 0.98679 14.6382 0.861766C14.5131 0.736742 14.3436 0.666504 14.1667 0.666504H5.83341C5.6566 0.666504 5.48703 0.736742 5.36201 0.861766C5.23699 0.98679 5.16675 1.15636 5.16675 1.33317V3.6665"
            stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M4.5 11.6665V13.6665C4.5 13.8433 4.57024 14.0129 4.69526 14.1379C4.82029 14.2629 4.98986 14.3332 5.16667 14.3332H14.8333C15.0101 14.3332 15.1797 14.2629 15.3047 14.1379C15.4298 14.0129 15.5 13.8433 15.5 13.6665V7.6665"
            stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M7.83333 3.6665H1.16667C0.798477 3.6665 0.5 3.96498 0.5 4.33317V10.9998C0.5 11.368 0.798477 11.6665 1.16667 11.6665H7.83333C8.20152 11.6665 8.5 11.368 8.5 10.9998V4.33317C8.5 3.96498 8.20152 3.6665 7.83333 3.6665Z"
            stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M4.49992 9.6665C5.42039 9.6665 6.16658 8.77107 6.16658 7.6665C6.16658 6.56193 5.42039 5.6665 4.49992 5.6665C3.57944 5.6665 2.83325 6.56193 2.83325 7.6665C2.83325 8.77107 3.57944 9.6665 4.49992 9.6665Z"
            stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M15.2467 14.1867L8.5 10.02" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.1667 10.9998L15.5001 7.6665" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.8334 7.6665H10.1667" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.8334 4.33301H10.1667" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11.6133 0.719727V7.66639" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8.38672 0.719727V2.31306" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg><span>Outlook</span>
      </button>
      <button class="button-email">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
          <path d="M15.5 4.5L8 7.83333L0.5 4.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M11.5001 1.54675V12.1668H14.1668C14.5204 12.1668 14.8595 12.0263 15.1096 11.7762C15.3596 11.5262 15.5001 11.187 15.5001 10.8334V2.83342C15.5039 2.48503 15.4167 2.14169 15.247 1.8374C15.0773 1.53312 14.831 1.27844 14.5326 1.09859C14.2342 0.91874 13.894 0.819963 13.5457 0.812041C13.1974 0.804118 12.8531 0.887325 12.5468 1.05342L8.00012 3.16675L3.45345 1.05342C3.14718 0.887325 2.80284 0.804118 2.45452 0.812041C2.1062 0.819963 1.766 0.91874 1.4676 1.09859C1.1692 1.27844 0.922957 1.53312 0.753262 1.8374C0.583567 2.14169 0.496305 2.48503 0.50012 2.83342V10.8334C0.50012 11.187 0.640596 11.5262 0.890644 11.7762C1.14069 12.0263 1.47983 12.1668 1.83345 12.1668H4.50012V1.54675"
            stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg><span>Gmail</span>
      </button>
    </div> -->
  </div>
</template>
<script setup lang="ts">
import { usePodStore } from '@/stores/pod'
import { onBeforeUnmount, onMounted, ref } from 'vue'

const pod = usePodStore()

let checkInterval: null | any = null

let networkCount = 0

const initialRequest = ref(true)

async function checkEmail() {
  if (!pod.optInForm.email || pod.optInForm.email === '') {
    pod.showConfirmation = false
  }

  const controller = new AbortController()
  const timeoutId = setTimeout(() => controller.abort(), 4_000)

  try {
    const res = await fetch(import.meta.env.VITE_API_URL + '/rest/verifyemail', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json'
      },
      signal: controller.signal,
      body: JSON.stringify({ email: pod.optInForm.email })
    })

    if (!res.ok) {
      throw new Error('Network response was not ok')
    }

    const data = await res.json()

    if (data.success) {
      pod.showConfirmation = false
      pod.started = true

      return
    }
  } catch (error) {
    return
  } finally {
    clearTimeout(timeoutId)

    if (initialRequest.value) {
      initialRequest.value = false
    }

    networkCount++

    if (networkCount % 5 === 0) {
      setCheckInterval()
    }
  }
}

function setCheckInterval() {
  let delay = 1_000

  if (checkInterval !== null) {
    delay = (networkCount / 5) * 1_000

    if (delay > 5_000) {
      return
    }

    clearInterval(checkInterval)
  }

  checkInterval = setInterval(checkEmail, delay)
}

onMounted(() => {
  checkEmail()
  setCheckInterval()
})

onBeforeUnmount(() => {
  if (checkInterval !== null) {
    clearInterval(checkInterval)
  }
})
</script>
