<template>
  <div ref="canvasOpuspodWrapper" id="canvas-opuspod-wrapper" class="flex lg:flex-grow lg:aspect-auto" :class="{
    'fixed inset-0 w-full h-full z-20': fullScreen,
    relative: !fullScreen,
    'hidden lg:flex': podStore.currentStep > 9,
    'aspect-video': !preview,
    'flex-grow': preview
  }">
    <canvas ref="renderer" id="canvas-opuspod" class="absolute inset-0 w-full h-full bg-transparent outline-none"
      :class="{
        'aspect-auto': fullScreen
      }"></canvas>
    <div class="absolute mb-1 ml-1 overflow-hidden pointer-events-none right-2 bottom-2">
      <div class="flex flex-row-reverse items-end gap-3">
        <div class="flex flex-col-reverse gap-3">
          <button @click="openOptions = !openOptions" class="render-square-button">
            <svg v-show="!openOptions" xmlns="http://www.w3.org/2000/svg" class="h-[14.6px] w-[14.6px] lg:w-6 lg:h-6"
              viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_4985_2691)">
                <path d="M5.07977 12H1.25977" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M22.738 12L10.006 12" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M13.994 20.1172L1.26099 20.1172" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M22.738 20.1172H18.92" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M13.994 3.88281L1.26099 3.88281" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M22.738 3.88281L18.92 3.88281" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M5.07996 12.0021C5.07996 12.6553 5.33945 13.2818 5.80135 13.7437C6.26325 14.2056 6.88973 14.4651 7.54296 14.4651C8.19618 14.4651 8.82266 14.2056 9.28456 13.7437C9.74646 13.2818 10.006 12.6553 10.006 12.0021C10.006 11.3488 9.74646 10.7224 9.28456 10.2605C8.82266 9.79856 8.19618 9.53906 7.54296 9.53906C6.88973 9.53906 6.26325 9.79856 5.80135 10.2605C5.33945 10.7224 5.07996 11.3488 5.07996 12.0021Z"
                  stroke="white" stroke-width="1.5" />
                <path
                  d="M13.994 20.1188C13.994 20.772 14.2535 21.3985 14.7154 21.8604C15.1773 22.3223 15.8038 22.5818 16.457 22.5818C17.1102 22.5818 17.7367 22.3223 18.1986 21.8604C18.6605 21.3985 18.92 20.772 18.92 20.1188C18.92 19.4655 18.6605 18.8391 18.1986 18.3772C17.7367 17.9153 17.1102 17.6558 16.457 17.6558C15.8038 17.6558 15.1773 17.9153 14.7154 18.3772C14.2535 18.8391 13.994 19.4655 13.994 20.1188Z"
                  stroke="white" stroke-width="1.5" />
                <path
                  d="M13.994 3.88488C13.994 4.5381 14.2535 5.16458 14.7154 5.62648C15.1773 6.08838 15.8038 6.34788 16.457 6.34788C17.1102 6.34788 17.7367 6.08838 18.1986 5.62648C18.6605 5.16458 18.92 4.5381 18.92 3.88488C18.92 3.23165 18.6605 2.60517 18.1986 2.14327C17.7367 1.68137 17.1102 1.42188 16.457 1.42188C15.8038 1.42188 15.1773 1.68137 14.7154 2.14327C14.2535 2.60517 13.994 3.23165 13.994 3.88488Z"
                  stroke="white" stroke-width="1.5" />
              </g>
              <defs>
                <clipPath id="clip0_4985_2691">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg v-show="openOptions" xmlns="http://www.w3.org/2000/svg" class="h-[14.6px] w-[14.6px] lg:w-6 lg:h-6"
              viewBox="0 0 24 24" fill="none">
              <path d="M0.75 23.249L23.25 0.749023" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M23.25 23.249L0.75 0.749023" stroke="white" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </button>
          <Transition name="top">
            <button @click="toggleFullscreen()" v-show="openOptions && !preview" class="render-square-button">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-[14.6px] w-[14.6px] lg:w-6 lg:h-6" viewBox="0 0 24 24"
                fill="none">
                <path d="M23.25 0.748047L0.75 23.248" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M23.25 7.49805V0.748047L16.5 0.748047" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M0.75 16.498L0.75 23.248H7.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M0.75 0.748047L23.25 23.248" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M0.75 7.49805L0.75 0.748047L7.5 0.748047" stroke="white" stroke-width="1.5"
                  stroke-linecap="round" stroke-linejoin="round" />
                <path d="M23.25 16.498V23.248H16.5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </button>
          </Transition>
          <Transition name="top">
            <button @click="toggleDoors()" v-show="openOptions" class="render-square-button" :class="{
              accent: podStore.currentStep === 3 && !hasToggledDoors
            }">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-[14.6px] w-[14.6px] lg:w-6 lg:h-6" viewBox="0 0 20 24"
                fill="none">
                <path
                  d="M7.72349 12.3605C7.52455 12.3605 7.36328 12.1994 7.36328 12.0003C7.36328 11.8014 7.52455 11.6401 7.72349 11.6401"
                  stroke="white" stroke-width="1.5" />
                <path
                  d="M7.72363 12.3605C7.92257 12.3605 8.08384 12.1994 8.08384 12.0003C8.08384 11.8014 7.92257 11.6401 7.72363 11.6401"
                  stroke="white" stroke-width="1.5" />
                <path
                  d="M14.8003 3.6001L17.9503 3.6001C18.2288 3.6001 18.4959 3.75287 18.6928 4.02479C18.8897 4.29672 19.0003 4.66554 19.0003 5.0501L19.0003 19.5501C19.0003 19.9346 18.8897 20.3035 18.6928 20.5754C18.4959 20.8474 18.2288 21.0001 17.9503 21.0001H14.8003"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M10.8598 22.7908L2.21489 21.5997C2.04267 21.5742 1.88552 21.4871 1.77251 21.3547C1.65949 21.2222 1.59827 21.0534 1.60014 20.8793L1.60014 4.0408C1.60291 3.87854 1.66037 3.72196 1.76323 3.59644C1.86609 3.47091 2.00832 3.3838 2.16686 3.3492L10.8118 1.21678C10.9168 1.19386 11.0255 1.19471 11.1301 1.21926C11.2347 1.24381 11.3325 1.29144 11.4164 1.35866C11.5001 1.42589 11.5678 1.51101 11.6145 1.60778C11.6611 1.70457 11.6855 1.81055 11.6859 1.91798L11.6859 22.0896C11.6859 22.1927 11.6634 22.2945 11.6202 22.388C11.5769 22.4816 11.5137 22.5646 11.4351 22.6313C11.3565 22.698 11.2644 22.7468 11.1651 22.7744C11.0657 22.8018 10.9615 22.8075 10.8598 22.7908Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </Transition>
          <Transition name="top">
            <button @click="toggleRulers()" v-show="openOptions" class="render-square-button" :class="{
              accent: podStore.currentStep === 1 && !hasToggledRulers
            }">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-[14.6px] w-[14.6px] lg:w-6 lg:h-6" viewBox="0 0 24 24"
                fill="none">
                <path d="M13.6255 4.67871L15.6131 6.66637" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M10.6436 7.66064L12.6312 9.6483" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M7.66211 10.6426L9.64977 12.6302" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M4.67969 13.6245L6.66734 15.6122" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M1.2002 17.1033L17.1033 1.2002L23.0682 7.1641L7.16504 23.0672L1.2002 17.1033Z" stroke="white"
                  stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
          </Transition>
        </div>
        <Transition name="left">
          <div v-show="openOptions"
            class="p-1 lg:p-[6px] rounded bg-brand-black/40 h-fit flex items-center gap-1 text-white shadow">
            <button @click="setCamera('camera_buiten')"
              class="py-1 px-3 lg:py-[5px] rounded transition-all pointer-events-auto"
              :class="{ 'bg-white text-brand-primary shadow': selected === 'camera_buiten' }">
              Buiten
            </button>
            <button @click="setCamera('camera_verblijf')"
              class="py-1 px-3 lg:py-[5px] rounded transition-all pointer-events-auto"
              :class="{ 'bg-white text-brand-primary shadow': selected === 'camera_verblijf' }">
              Binnen
            </button>
            <button v-if="hasBerging" @click="setCamera('camera_berging')"
              class="py-1 px-3 lg:py-[5px] rounded transition-all pointer-events-auto"
              :class="{ 'bg-white text-brand-primary shadow': selected === 'camera_berging' }">
              Berging
            </button>
          </div>
        </Transition>
      </div>
    </div>

    <!-- LoadingScreen -->
    <div v-show="isLoading" id="loadingScreen"
      class="absolute inset-0 grid w-full h-full bg-gray-100 place-items-center">
      <div class="flex flex-col items-center w-full max-w-[60vw] lg:max-w-lg">
        <img src="@/assets/images/logo-loading.svg" alt="OPUS" />
        <!-- Loading bar -->
        <div role="status">
          <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-300 fill-brand-green"
            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor" />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill" />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useModx } from '@/composables/useModx'
import { useCamera } from '@/composables/useCamera'
// import { useModal } from '@/composables/useModal'
import * as Sentry from '@sentry/vue'

import type {
  ConfiguratorRender,
  SetConfiguratorRenderOptions,
  ConfiguratorRenderOptions
} from '@/types/configurator'
import { usePodStore } from '@/stores/pod'
import { useGtm } from '@/composables/useGtm'

declare global {
  interface Window {
    pod: ConfiguratorRender
  }
}

const props = defineProps<{
  preview: boolean
}>()

const modx = useModx()

const podStore = usePodStore()

const renderer = ref<HTMLCanvasElement | null>(null)

const fullScreen = ref(false)

const canvasOpuspodWrapper = ref<HTMLDivElement | null>(null)

let firstRender = true

function toggleFullscreen() {
  fullScreen.value = !fullScreen.value
  setTimeout(() => {
    resizeRender()
  }, 0)

  useGtm().addEvent('configurator-interaction-fullscreen')
}

const hasToggledDoors = ref(false)

function toggleDoors() {
  if (window.pod !== undefined && window.pod.engine !== undefined) {
    window.pod.toggle_all()
  }

  if (!hasToggledDoors.value) {
    hasToggledDoors.value = true
  }

  useGtm().addEvent('configurator-interaction-doors')
}

const hasToggledRulers = ref(false)
function toggleRulers() {
  if (window.pod !== undefined && window.pod.engine !== undefined) {
    window.pod.toggleRulers()
  }

  if (!hasToggledRulers.value) {
    hasToggledRulers.value = true
  }

  useGtm().addEvent('configurator-interaction-rulers')
}

podStore.$subscribe((mutator: any) => {
  const keysWatched = ['price']

  if (keysWatched.includes(mutator.events?.key ?? '')) {
    return
  }

  // Wait for next tick to complete animations
  setTimeout(() => {
    renderPod()
  }, 50)

  podStore.calculatePrice()
})

const openOptions = ref(false)

const selected = ref('camera_buiten')

const hasBerging = computed(() => {
  return podStore.renderOptions().berging
})

function setCamera(camera: 'camera_verblijf' | 'camera_buiten' | 'camera_berging') {
  selected.value = camera
  if (window.pod !== undefined && window.pod.changeCamera !== undefined) {
    window.pod.changeCamera(camera)
  }

  useGtm().addEvent('configurator-interaction-camera')
}

function resizeRender() {
  if (window?.pod?.engine !== undefined) {
    try {
      window.pod.engine.resize()
      window.pod.setCameraRadius()
    } catch {
      return // Non-blocking error
    }
  }
}

function renderPod() {
  // Check if the render has been initialized
  if (window?.pod?.getOptions() === undefined) {
    return
  }

  // Set Sentry tag for debugging
  if (window.pod?.engine?.isWebGPU !== undefined) {
    Sentry.setTag('babylon_render_type', window.pod.engine.isWebGPU ? 'WebGPU' : 'WebGL')
  }

  const currentRender = window.pod.getOptions()
  const newRender = podStore.renderOptions()

  let windowsLeft = false

  type ConfiguratorRenderOptionKeys = keyof ConfiguratorRenderOptions

  const differentKeys = (Object.keys(currentRender) as ConfiguratorRenderOptionKeys[]).filter(
    (key) => {
      if (newRender[key] === null) {
        return false
      }

      if (key === 'windows') {
        // Compare windows left to see if length and values are the same
        if (currentRender[key]?.left?.length !== newRender[key]?.left?.length) {
          windowsLeft = true
          return true
        }

        // Compare windows right to see if length and values are the same
        if (currentRender[key]?.right?.length !== newRender[key]?.right?.length) {
          return true
        }

        return false
      }

      return currentRender[key] !== newRender[key]
    }
  )

  // Find the differences between the currentRender object and the podStore.renderOptions object
  const differences = differentKeys.reduce(
    (acc: SetConfiguratorRenderOptions, key: ConfiguratorRenderOptionKeys) => {
      // @ts-ignore
      acc[key] = newRender[key]

      return acc
    },
    {}
  )

  if (Object.keys(differences).length > 0) {
    window.pod.set(differences)
    animateCanvas(differences, windowsLeft)
  }

  if (firstRender) {
    firstRender = false
    window.pod.set(newRender)

    // Check if render is resume
    if (!props.preview && podStore.options.podtype !== null) {
      podStore.calculatePrice()
    }
  }

  if (props.preview) {
    setTimeout(() => {
      resizeRender()
    }, 0)
  }
}

function animateCanvas(differences: SetConfiguratorRenderOptions, windowsLeft: boolean) {
  if (differences?.width || differences?.depth) {
    if (!firstRender) {
      resizeRender()
    }
  }

  if (differences?.orientation || differences?.planken) {
    // Prevent animations
    return
  }

  if (differences?.windows) {
    if (podStore.currentStep < 3) {
      return
    }
    useCamera(windowsLeft ? 'linkerzijde' : 'rechterzijde')
    return
  }

  if (differences?.dakraam) {
    if (podStore.currentStep === 4) {
      useCamera('bovenzijde')
      return
    }
  }

  if (differences?.sedum) {
    if (podStore.currentStep === 5) {
      useCamera('bovenzijde')
      return
    }
  }
}

async function loadPod() {
  const version = (await modx).version !== '' ? `?v=${(await modx).version}` : ''

  let script = document.createElement('script')
  script.src = import.meta.env.VITE_API_URL + '/assets/js/dynamic_configurator.js' + version
  script.async = true
  script.crossOrigin = 'anonymous'

  document.body.appendChild(script)

  // Add a resize event listener to the window object
  window.addEventListener('resize', () => {
    resizeRender()
  })

  // wait untill window?.pod?.getOptions() is available
  let interval = setInterval(() => {
    if (window?.pod?.getOptions() !== undefined) {
      clearInterval(interval)
      renderPod()
    }
  }, 100)
}

const isLoading = ref(true)

function checkLoading() {
  if (firstRender === true || window?.pod?.getReady() !== true) {
    return
  }

  isLoading.value = false
}

let intervalBackground: null | any = null
async function syncBackground() {
  if (
    canvasOpuspodWrapper.value === null ||
    window.pod === undefined ||
    window.pod.engine === undefined ||
    window.pod.camera === undefined ||
    // @ts-ignore
    BABYLON === undefined
  ) {
    return
  }

  try {
    // @ts-ignore
    var dataUrl = await BABYLON.Tools.CreateScreenshotAsync(window.pod.engine, window.pod.scene.activeCamera, {
      precision: 0.5
    })
    canvasOpuspodWrapper.value.style.backgroundImage = 'url(' + dataUrl + ')'
  } catch {
    return
  }
}

let loadingInterval: null | any = null

function handleCanvasEvent() {
  useGtm().addEvent('configurator-interaction-3d')
}

onMounted(() => {
  if (typeof window.pod !== 'undefined') {
    return
  }

  // checkLoading every 100ms
  loadingInterval = setInterval(() => {
    checkLoading()

    if (isLoading.value === false) {
      clearInterval(loadingInterval)

      setTimeout(() => {
        openOptions.value = true
      }, 3000)
    }
  }, 200)

  loadPod()

  // Listen for touch or click events on the renderer
  if (renderer.value !== null) {
    renderer.value.addEventListener('click', handleCanvasEvent)
    renderer.value.addEventListener('touchstart', handleCanvasEvent)
  }

  intervalBackground = setInterval(() => {
    syncBackground()
  }, 5_000)
})

onUnmounted(() => {
  // Hotfix
  // window.removeEventListener('unhandledrejection', handleUnhandledRejection)

  window.removeEventListener('resize', () => {
    resizeRender()
  })

  if (renderer.value !== null) {
    renderer.value.removeEventListener('click', handleCanvasEvent)
    renderer.value.removeEventListener('touchstart', handleCanvasEvent)
  }

  if (loadingInterval !== null) {
    clearInterval(loadingInterval)
  }

  if (intervalBackground !== null) {
    clearInterval(intervalBackground)
  }
})

useGtm().addEvent('configurator-gestart')
</script>

<style scoped>
#canvas-opuspod-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.left-enter-active,
.left-leave-active,
.top-enter-active,
.top-leave-active {
  transition:
    transform 0.3s ease-in,
    opacity 0.3s ease-in;
}

.left-enter-from,
.left-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.top-enter-from,
.top-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
