export function useHubspotForm(): Promise<void> {
  return new Promise((resolve) => {
    if (window.hbspt) {
      resolve()
    } else {
      const script = document.createElement('script')
      script.src = '//js-eu1.hsforms.net/forms/embed/v2.js'
      document.body.appendChild(script)

      script.addEventListener('load', () => {
        if (window.hbspt) {
          resolve()
        }
      })
    }
  })
}
