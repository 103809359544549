<template>
  <div v-if="climateOptionsArray.length > 0" class="category">
    <div class="category-label">
      <span>Klimaat & Elektra</span>
    </div>
    <InputToggle v-if="climateOptionsArray.includes('Climate Control')" title="Climate Control"
      description="Verwarmen, koelen en ontvochtigen" v-model="pod.options.climate_control" />
    <InputToggle v-if="climateOptionsArray.includes('Vloerverwarming')" title="Vloerverwarming"
      description="Voor een comfortabele warmteverdeling" v-model="pod.options.floor_heating" />
    <InputToggle v-if="climateOptionsArray.includes('Groen Sedumdak')" title="Groen Sedumdak"
      description="Duurzaam en werkt isolerend" v-model="pod.options.sedum" />
  </div>
  <div class="category">
    <div class="category-label">
      <span>Toilet, Badkamer & Keuken</span>
    </div>

    <InputToggle title="Toiletruimte" class="border-b-0" description="Inclusief volledige uitrusting óf casco"
      v-model="pod.options.optie_toilet" :disabled="!sanitairOptionsArray.includes('Toiletruimte')">
      <template v-slot:tooltip>
        <ToolTip @tooltip-clicked="toiletTooltip()" />
      </template>
    </InputToggle>

    <div v-if="pod.options.optie_toilet"
      class="flex flex-col items-start w-full gap-2 py-3 border-b border-brand-tint-lightgreen">
      <span class="shrink-0">Uitvoering</span>
      <div class="px-[6px] py-[7px] rounded bg-brand-tint-orange text-black h-fit flex items-center w-full gap-1">
        <button @click="pod.options.optie_sanitair_casco = false"
          class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2" :class="{
            'bg-white text-brand-primary shadow': !pod.options.optie_sanitair_casco
          }">
          <span>Compleet</span>
        </button>

        <button @click="pod.options.optie_sanitair_casco = true"
          class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2" :class="{
            'bg-white text-brand-primary shadow': pod.options.optie_sanitair_casco
          }">
          <span>Casco</span>
        </button>
      </div>
      <div class="w-full text-center">
        <button class="text-brand-secondary" @click="toiletTooltip()">Meer informatie</button>
      </div>
    </div>

    <InputToggle v-if="sanitairOptionsArray.includes('Badkamer')" class="border-b-0" title="Badkamer"
      description="Inclusief volledige uitrusting óf casco" v-model="pod.options.optie_badkamer">
      <template v-slot:tooltip>
        <ToolTip @tooltip-clicked="badkamerTooltip()" />
      </template>
    </InputToggle>

    <div v-if="pod.options.optie_badkamer" class="flex flex-col items-start w-full gap-2 py-3">
      <span class="shrink-0">Uitvoering</span>
      <div class="px-[6px] py-[7px] rounded bg-brand-tint-orange text-black h-fit flex items-center w-full gap-1">
        <button @click="pod.options.optie_sanitair_casco = false"
          class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2" :class="{
            'bg-white text-brand-primary shadow': !pod.options.optie_sanitair_casco
          }">
          <span>Compleet</span>
        </button>

        <button @click="pod.options.optie_sanitair_casco = true"
          class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2" :class="{
            'bg-white text-brand-primary shadow': pod.options.optie_sanitair_casco
          }">
          <span>Casco</span>
        </button>
      </div>
      <div class="w-full text-center">
        <button class="text-brand-secondary" @click="badkamerTooltip()">Meer informatie</button>
      </div>
    </div>

    <div v-if="pod.options.optie_badkamer && pod.options.width >= 6"
      class="flex flex-col items-start w-full gap-2 py-3 border-b border-brand-tint-lightgreen">
      <span class="shrink-0">Positie in pod</span>
      <div class="px-[6px] py-[7px] rounded bg-brand-tint-orange text-black h-fit flex items-center w-full gap-1">
        <button @click="pod.options.plaatsing_badkamer = 'hoek'"
          class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2" :class="{
            'bg-white text-brand-primary shadow': pod.options.plaatsing_badkamer === 'hoek'
          }">
          <span>Hoek</span>
        </button>


        <button @click="pod.options.plaatsing_badkamer = 'midden'"
          class="px-3 py-[5px] rounded transition-all pointer-events-auto w-1/2" :class="{
            'bg-white text-brand-primary shadow': pod.options.plaatsing_badkamer === 'midden'
          }">
          <span>Midden</span>
        </button>
      </div>
    </div>

    <InputToggle v-if="sanitairOptionsArray.includes('Keukenblok')" title="Keukenblok"
      description="Keukenblok met spoelbak, kraan en kastruimte" v-model="pod.options.optie_keuken">
      <template v-slot:tooltip>
        <ToolTip @tooltip-clicked="keukenTooltip()" />
      </template>
    </InputToggle>
  </div>
  <div v-if="additionalOptionsArray.length > 0" class="category">
    <div class="category-label">
      <span>Overige opties</span>
    </div>
    <InputToggle v-if="additionalOptionsArray.includes('Casco uitvoeren (minderprijs)')"
      title="Casco uitvoeren (minderprijs)" v-model="pod.options.casco" />
    <InputToggle v-if="additionalOptionsArray.includes('Zwarte gevelbekleding')" title="Zwarte gevelbekleding"
      description="Gevelbekleding met een zwarte coating" v-model="pod.options.dark_cladding" />
  </div>
</template>
<script setup lang="ts">
import { usePodStore } from '@/stores/pod'
import { useOptions } from '@/composables/useOptions'

import InputToggle from '@/components/Input/InputToggle.vue'
import ToolTip from '@/components/ToolTip.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { useModal } from '@/composables/useModal'
import { useModx } from '@/composables/useModx'
import { useCamera } from '@/composables/useCamera'
import type { ModelObject } from '@/types/products'
import { useGtm } from '@/composables/useGtm'


const modx = useModx()

const pod = usePodStore()

const model = ref(<ModelObject | null>null)

useOptions().then((optionStore) => {
  model.value = optionStore.getModel(pod.options)
})

const climateOptionsArray = computed(() => {
  if (model.value === null) return []

  let options = []

  if (
    model.value.opties_overig.find((option) => option.optie.includes('Climate Control')) !==
    undefined
  ) {
    options.push('Climate Control')
  }

  if (
    model.value.opties_vloerverwarming.length > 0
  ) {
    options.push('Vloerverwarming')
  }

  if (
    model.value.opties_sedum.find((option) => option.optie.includes('Sedumdak')) !==
    undefined
  ) {
    options.push('Groen Sedumdak')
  }

  return options
})

const sanitairOptionsArray = computed(() => {
  if (model.value === null) return []

  let options = []

  if (model.value.opties_sanitair.length > 0) {

    if (
      model.value.opties_sanitair.find((option) => option.optie.includes('Toiletruimte')) !== undefined
    ) {
      options.push('Toiletruimte')
    }

    if (
      model.value.opties_sanitair.find((option) => option.optie.includes('Badkamer')) !== undefined
    ) {
      options.push('Badkamer')
    }

    if (
      model.value.opties_sanitair.find((option) => option.optie.includes('Keukenblok')) !== undefined
    ) {
      options.push('Keukenblok')
    }
  }

  return options
})

const additionalOptionsArray = computed(() => {
  if (model.value === null) return []

  let options = []

  if (model.value.opties_casco.length > 0) {
    options.push('Casco uitvoeren (minderprijs)')
  }

  // if (
  //   model.value.opties_gevelbekleding.find((option) =>
  //     option.optie.includes('zwarte gevelbekleding')
  //   ) !== undefined
  // ) {
  //   options.push('Zwarte gevelbekleding')
  // }

  return options
})

// Watch for conflicting options
watch(
  () => pod.options.optie_toilet,
  (value) => {
    if (value) {
      pod.options.optie_badkamer = false
    }
  }
)

watch(
  () => pod.options.optie_badkamer,
  async (value) => {
    if (value && pod.options.optie_toilet) {
      pod.options.optie_toilet = false
    }
  }
)

async function toiletTooltip() {
  useModal(
    (await modx).content['opmerking-toiletruimte']?.title,
    (await modx).content['opmerking-toiletruimte']?.content
  )
}

async function badkamerTooltip() {
  useModal(
    (await modx).content['opmerking-badkamer']?.title,
    (await modx).content['opmerking-badkamer']?.content
  )
}

async function keukenTooltip() {
  useModal(
    (await modx).content['opmerking-keukenblok']?.title,
    (await modx).content['opmerking-keukenblok']?.content
  )
}

onMounted(() => {
  useCamera('vooraanzicht')
})

useGtm().addEvent('configurator-stap-opties')
</script>
