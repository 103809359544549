<template>
  <Menu as="div" class="relative inline-block w-full text-left">
    <div ref="dropdownButton">
      <MenuButton @click="scrollToButton()"
        class="inline-flex w-full items-center justify-between gap-x-2 rounded bg-white p-3 text-black ring-[1.5px] ring-inset ring-brand-green hover:bg-gray-50">
        {{ current }}
        <IconChevronDown class="w-4 h-4 text-brand-green" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
      <MenuItems
        class="right-0 z-50 w-full mt-2 max-h-40 lg:max-h-none overflow-y-auto branded-scroll origin-top-right bg-white rounded shadow-md ring-[1.5] ring-brand-green ring-opacity-50 focus:outline-none"
        :class="{
        'absolute': floating
      }">
        <div class="flex flex-col py-2">
          <MenuItem v-for="o in options" :key="o.value">
          <button :disabled="o.disabled ?? false" @click="value = o.value" class="button-dropdown"
            :class="{ 'button-dropdown-selected': value === o.value }">
            {{ o.label }}
          </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import IconChevronDown from '@/components/Icons/IconChevronDown.vue'

import { computed, ref } from 'vue'

type Option = {
  label: string
  value: string | number
  disabled?: boolean
}

const props = defineProps({
  options: {
    type: Array as () => Option[],
    required: true
  },
  floating: {
    type: Boolean,
    default: true
  }
})

const dropdownButton = ref<HTMLElement | null>(null)

const value = defineModel()

const current = computed(() => {
  return props.options.find((o) => o.value === value.value)?.label
})

function scrollToButton() {
  // If current device is not mobile, return
  if (window.innerWidth > 768) return

  // Await next tick
  setTimeout(() => {
    dropdownButton.value?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, 50)
}

</script>
